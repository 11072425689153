import Card from 'Components/Card';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'Components/Button';
import { formatCurrency } from 'Utils/Functions';
import ModalComponent from 'Components/Modal';
import { useState } from 'react';
import CreditoService from 'Services/Http/Credito.service';
import { useNavigate } from 'react-router-dom';
import Descargable from 'Components/Descargable';

const Credito = (props) => {
  const { expedienteData, full = false } = props;
  const navigate = useNavigate();
  const [expediente, setExpediente] = useState(expedienteData);
  const [fnMethod, setFnMethod] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [stateModal, setStateModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [onClose, setOnClose] = useState(false);
  const changeState = (state) => {
    setShowModal(state);
    if (onClose) {
      navigate(-1);
    }
  };

  const dispercionModal = () => {
    setFnMethod('dispercion');
    setTypeModal('decision');
    setMessageModal('¿Esta seguro que el prestamo ya ha sido dispersado?');
    setStateModal('warning');
    setShowModal(true);
  };
  const liquidacionModal = () => {
    setFnMethod('liquidacion');
    setTypeModal('decision');
    setMessageModal('¿Esta seguro que el prestamo ya ha sido liquidado?');
    setStateModal('warning');
    setShowModal(true);
  };

  const CancelacionModal = () => {
    setFnMethod('cancelacion');
    setTypeModal('motivo');
    setMessageModal('¿Esta seguro que desea cancelar el prestamo?');
    setStateModal('warning');
    setShowModal(true);
  };

  const RechazoModal = () => {
    setFnMethod('rechazo');
    setTypeModal('motivo');
    setMessageModal('¿El cliente ha rechazado el credito?');
    setStateModal('warning');
    setShowModal(true);
  };

  const dispercion = async () => {
    setShowModal(false);
    try {
      const response = await CreditoService.dispercion(expedienteData.id);
      setExpediente(response?.data?.expediente);
      setTypeModal('notificacion');
      setMessageModal('Se ha registrado la dispercion correctamente');
      setStateModal('success');
      setShowModal(true);
    } catch (e) {
      console.log(e);
      setShowModal(false);
      setTypeModal('notificacion');
      setMessageModal('Ups, hubo un error intente de nuevo');
      setStateModal('error');
      setShowModal(true);
    }
  };

  const liquidacion = async () => {
    setShowModal(false);
    try {
      await CreditoService.liquidacion(expedienteData.id);
      setTypeModal('notificacion');
      setMessageModal('Se ha registrado la liquidacion correctamente');
      setStateModal('success');
      setShowModal(true);
      setOnClose(true);
    } catch (e) {
      console.log(e);
      setShowModal(false);
      setTypeModal('notificacion');
      setMessageModal('Ocurrio un error valide e intente de nuevo.');
      setStateModal('succes');
      setShowModal(true);
    }
  };

  const cancelacion = async (data, setLoader) => {
    setLoader(true);
    setShowModal(false);
    try {
      const params = { ...data, idExpediente: expedienteData.id };
      await CreditoService.cambioEstatus('cancelado', params);
      setTypeModal('notificacion');
      setMessageModal('Se ha registrado la cancelado correctamente');
      setStateModal('success');
      setLoader(false);
      setShowModal(true);
      setOnClose(true);
    } catch (e) {
      console.log(e);
      setLoader(false);
      setShowModal(false);
      setTypeModal('notificacion');
      setMessageModal('Ocurrio un error valide e intente de nuevo.');
      setStateModal('succes');
      setShowModal(true);
    }
  };

  const rechazo = async (data, setLoader) => {
    setLoader(true);
    setShowModal(false);
    try {
      const params = { ...data, idExpediente: expedienteData.id };
      await CreditoService.cambioEstatus('cancelado', params);
      setTypeModal('notificacion');
      setMessageModal('Se ha registrado el rechazo correctamente');
      setStateModal('success');
      setLoader(false);
      setShowModal(true);
      setOnClose(true);
    } catch (e) {
      console.log(e);
      setLoader(false);
      setShowModal(false);
      setTypeModal('notificacion');
      setMessageModal('Ocurrio un error valide e intente de nuevo.');
      setStateModal('succes');
      setShowModal(true);
    }
  };

  const actionOK = async (data, setLoader) => {
    switch (fnMethod) {
      case 'dispercion':
        await dispercion();
        break;
      case 'liquidacion':
        await liquidacion();
        break;
      case 'cancelacion':
        await cancelacion(data, setLoader);
        break;
      case 'rechazo':
        await rechazo(data, setLoader);
        break;
    }
  };

  return (
    <Card title="Estatus de Credito">
      <div className={full ? 'xl:w-full' : 'xl:w-1/2'}>
        <div className="flex flex-col md:flex-row my-3 md:grid md:grid-flow-col md:grid-cols-1 justify-stretch">
          <div>
            <div>Id Credito: </div>
            <div className="font-semibold">
              {expediente?.folioCredito > 0 ? expediente?.folioCredito : ''}
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row my-3 md:grid md:grid-flow-col md:grid-cols-3 justify-stretch">
          <div>
            <div>Monto: </div>
            <div className="font-semibold">
              {expediente?.montoAprovado ? formatCurrency(expediente?.montoAprovado) : 'No'}
            </div>
          </div>
          <div>
            <div>Monto de dispercion: </div>
            <div className="font-semibold">
              {expediente?.montoDispercion
                ? formatCurrency(expediente?.montoDispercion)
                : formatCurrency(expediente?.montoAprovado)}
            </div>
          </div>
          <div>
            <div>Monto Total: </div>
            <div className="font-semibold">
              {expediente?.montoTotal ? formatCurrency(expediente?.montoTotal) : 'No'}
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row my-3 md:grid md:grid-flow-col md:grid-cols-3 justify-stretch gap-3">
          <div>
            <div>Autorizado: </div>
            <div className="font-semibold">
              {expediente?.autorizacion
                ? moment(expediente?.autorizacion).format('DD/MM/YYYY')
                : 'No'}
            </div>
          </div>
          <div>
            <div>Dispercion: </div>
            <div className="font-semibold">
              {expediente?.dispercion ? moment(expediente?.dispercion).format('DD/MM/YYYY') : 'No'}
            </div>
          </div>
          <div>
            <div>Dispersado: </div>
            <div className="font-semibold">{expediente?.dispersado ? 'Si' : 'No'}</div>
          </div>
        </div>
        {expediente.auth ? (
          <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col md:grid-cols-3 justify-stretch">
            <Descargable
              id={expedienteData.id}
              typeMime={false}
              typeFile="pdf"
              file="amortizacion"
            />
            <Descargable id={expedienteData.id} typeMime={false} typeFile="pdf" file="pagare" />
            <Descargable id={expedienteData.id} typeMime={false} typeFile="pdf" file="contrato" />
          </div>
        ) : (
          ''
        )}
        <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col md:grid-cols-3 justify-stretch">
          {expediente.auth ? (
            <>
              {expediente?.idStatus != '6' && expediente?.idStatus != '7' ? (
                <>
                  {expediente?.dispersado ? (
                    <>
                      {expediente?.idStatus != '8' ? (
                        <Button label="Liquidado" onClick={() => liquidacionModal()} />
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        label="Dispersado"
                        color="success"
                        onClick={() => dispercionModal()}
                      />
                      <Button label="Cancelar" onClick={() => CancelacionModal()} />
                      <Button label="Rechazo" onClick={() => RechazoModal()} />
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <ModalComponent
        show={showModal}
        changeState={changeState}
        typeModal={typeModal}
        title={messageModal}
        stateModal={stateModal}
        actionOK={actionOK}
      />
    </Card>
  );
};

Credito.propTypes = {
  expedienteData: PropTypes.object,
  full: PropTypes.bool
};
export default Credito;
