import PropTypes from 'prop-types';

const Input = (props) => {
  const { error = null, placeholder = '', disabled } = props;
  return (
    <>
      <input
        autoComplete="off"
        className="input-base"
        {...props}
        placeholder={placeholder}
        disabled={disabled}
      />
      {error?.message && <span className="text-red-500">{error?.message}</span>}
    </>
  );
};

Input.propTypes = {
  error: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default Input;
