import React from 'react';
import { Route } from 'react-router-dom';
import CrearProspectoComponent from './Crear-Prospectos';
import ListarProspectoComponente from './Listar-Prospectos';

const ProspectosComponent = React.lazy(() => import('./Prospectos'));
const ProspectosRouter = (
  <Route path="prospectos" element={<ProspectosComponent />}>
    <Route path="" element={<ListarProspectoComponente />} />
    <Route path="crear" element={<CrearProspectoComponent />} />
  </Route>
);

export default ProspectosRouter;
