import Card from 'Components/Card';
import ClientesService from 'Services/Http/Clientes.service';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageComponent from 'Modules/Main/Validacion/Verificacion/Image';

const Aval = (props) => {
  const { id, idExpediente, idPersona } = props;
  const [aval, setAval] = useState({});
  useEffect(() => {
    getData();
  }, [id]);
  const getData = async () => {
    try {
      const response = await ClientesService.getEndorsementById(id);
      setAval(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Card title="Informacion del Aval" className="col-span-2 lg:col-span-1">
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Nombre</div>
            <div className="font-semibold">{aval.nombreCompleto}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Ocupacion</div>
            <div className="font-semibold">{aval.ocupacion}</div>
          </div>
          <div>
            <div>Parentesco</div>
            <div className="font-semibold">{aval?.descripcionParentesco}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Movil</div>
            <div className="font-semibold">{aval.movil}</div>
          </div>
          <div>
            <div>Fijo</div>
            <div className="font-semibold">{aval.fijo}</div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between my-3">
          <ImageComponent
            label="Ine Frontal"
            icon={['fa', 'id-card']}
            idPersona={idPersona}
            idExpediente={idExpediente}
            typePerson="AVAL"
            name="INE_FRONTAL"
          />
          <ImageComponent
            label="Ine Reverso"
            icon={['fa', 'money-check']}
            idPersona={idPersona}
            idExpediente={idExpediente}
            typePerson="AVAL"
            name="INE_REVERSO"
          />
        </div>
      </Card>
    </>
  );
};

Aval.propTypes = {
  id: PropTypes.object.isRequired,
  idExpediente: PropTypes.string,
  idPersona: PropTypes.string
};

export default Aval;
