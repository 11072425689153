import Card from 'Components/Card';
import Geolocalizacion from 'Modules/Main/Validacion/Verificacion/Geolocalizacion';
import Image from 'Modules/Main/Validacion/Verificacion/Image';
import DomicilioService from 'Services/Http/Domicilio.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const Domicilio = (props) => {
  const { id = null, aval = false, location, idPersona, idExpediente } = props;
  const [domicilio, setDomicilio] = useState({});
  const [typePerson, setTypePerson] = useState('');
  useEffect(() => {
    getDomicilio();
  }, [id]);
  useEffect(() => {
    aval ? setTypePerson('AVAL') : setTypePerson('CLIENTE');
  }, []);
  const getDomicilio = async () => {
    try {
      const response = await DomicilioService.getDomicilioById(id);
      setDomicilio(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Card
        title={aval ? 'Domicilio del aval' : 'Domicilio del cliente'}
        className="col-span-2 lg:col-span-1">
        {aval ? '' : <Geolocalizacion location={location} />}
        <div className="flex flex-col md:flex-row justify-between my-3">
          <Image
            label="Comprobante de domicilio"
            icon={['fa', 'house-user']}
            idPersona={idPersona}
            idExpediente={idExpediente}
            typePerson={typePerson}
            name="DOMICILIO"
          />
          {aval ? (
            ''
          ) : (
            <>
              <Image
                label="Fachada de domicilio"
                icon={['fa', 'house-circle-check']}
                idPersona={idPersona}
                idExpediente={idExpediente}
                typePerson={typePerson}
                name="FACHADA"
              />
            </>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Calle</div>
            <div className="font-semibold">{domicilio.calle}</div>
          </div>
          <div>
            <div>Numero Exterior</div>
            <div className="font-semibold">{domicilio.numeroExterior}</div>
          </div>
          <div>
            <div>Numero Interior</div>
            <div className="font-semibold">{domicilio.numeroInterior}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Codigo Postal</div>
            <div className="font-semibold">{domicilio.codigoPostal}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Estado</div>
            <div className="font-semibold">{domicilio.localidad?.d_estado}</div>
          </div>
          <div>
            <div>Municipio</div>
            <div className="font-semibold">{domicilio.localidad?.D_mnpio}</div>
          </div>
          <div>
            <div>Colonia</div>
            <div className="font-semibold">{domicilio.asentamiento}</div>
          </div>
        </div>
      </Card>
    </>
  );
};

Domicilio.propTypes = {
  id: PropTypes.object.isRequired,
  aval: PropTypes.bool.isRequired,
  location: PropTypes.object,
  idPersona: PropTypes.string.isRequired,
  idExpediente: PropTypes.string.isRequired
};

export default Domicilio;
