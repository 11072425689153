import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'Components/Button';
import ControllerDatePicker from 'Components/ControllerDatePicker';
import ControllerInput from 'Components/ControllerInput';
import ControllerSelect from 'Components/ControllerSelect';
import Label from 'Components/Label';
import ModalComponent from 'Components/Modal';
import ClientesService from 'Services/Http/Clientes.service';
import { CrearClienteSchema } from 'Services/yup/form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const CrearClienteComponent = () => {
  const [stateModal, setStateModal] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [typeModal, setTypeModal] = useState('');
  const [loader, setLoader] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      nombre: '',
      paterno: '',
      materno: '',
      fechaNacimiento: '',
      idEstadoCivil: '',
      curp: ''
    },
    resolver: yupResolver(CrearClienteSchema)
  });
  const submit = async (data) => {
    console.log(data);
    setLoader(true);
    try {
      await ClientesService.createProspecto(data);
      setLoader(false);
      setMessageModal('Guardado Exitoso');
      setTypeModal('success');
      setStateModal(true);
      reset();
    } catch (e) {
      console.log(e);
      setLoader(false);
      setStateModal(true);
      setTypeModal('error');
      setMessageModal(
        'Ocurrio un error, revise posibles duplicados u homologos, e intente de nuevo'
      );
    }
  };
  const changeState = (state) => {
    setStateModal(state);
  };

  const getEstadoCivil = async () => {
    try {
      const response = await ClientesService.getEstadoCivil();
      const data = response.data.map((item) => {
        return {
          label: item.descripcionEstadoCivil,
          value: item.id
        };
      });
      setEstadoCivil(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getEstadoCivil();
  }, []);

  return (
    <>
      <Label text={'Creación de prospecto a cliente'} />
      <form autoComplete="false">
        <div className="w-full lg:w-1/2 py-5 space-y-4 flex flex-col">
          <div className="w-full xl:w-1/2 space-y-4 flex flex-col">
            <ControllerInput
              control={control}
              name="nombre"
              error={errors?.nombre}
              type="text"
              placeholder="Nombre"
            />
            <ControllerInput
              control={control}
              name="paterno"
              error={errors?.paterno}
              type="text"
              placeholder="Apellido Paterno"
            />
            <ControllerInput
              control={control}
              name="materno"
              error={errors?.materno}
              type="text"
              placeholder="Apellido Materno"
            />
          </div>
          <div className="w-full xl:w-1/2 space-y-4 flex flex-col">
            <ControllerDatePicker
              control={control}
              name="fechaNacimiento"
              error={errors?.fechaNacimiento}
              placeholder="Fecha de nacimiento"
            />
            <ControllerSelect
              control={control}
              name="idEstadoCivil"
              error={errors?.idEstadoCivil}
              data={estadoCivil}
            />
            <ControllerInput
              control={control}
              name="curp"
              error={errors?.curp}
              type="text"
              placeholder="CURP"
            />
          </div>
        </div>
        <div className="w-full space-y-2 flex flex-col lg:w-1/2 xl:w-1/4 lg:flex-row lg:space-y-0 lg:space-x-3">
          <Button
            onClick={handleSubmit(submit)}
            label="Guardar"
            color="primary"
            type="button"
            loading={loader}
          />
          <Button onClick={reset} label="Limpiar" type="button" />
        </div>
      </form>
      <ModalComponent
        show={stateModal}
        changeState={changeState}
        typeModal="notificacion"
        title={messageModal}
        stateModal={typeModal}
      />
    </>
  );
};

export default CrearClienteComponent;
