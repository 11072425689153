import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLayout } from '../../../redux/slices';
import Button from 'Components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import { isMobile } from 'react-device-detect';
import MediaService from 'Services/Http/Media.service';

const Firma = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refCanvas = useRef();
  const calcPercentage = 0.8;
  const calcWidthPercentage = 0.96;
  const [width, setWidth] = useState(window.innerWidth * calcWidthPercentage);
  const [height, setHeight] = useState(window.innerHeight * calcPercentage);
  const [orientation, setOrientation] = useState('');
  const data = useSelector((state) => state.detalle?.firma);
  const [dataImages, setDataImages] = useState();
  const { id } = useParams();

  useEffect(() => {
    console.log(data);
    console.log(id);
    if (id in data) {
      setDataImages(data[id]);
    }
    function updateOrientation() {
      setOrientation(window.screen.orientation.type);
    }
    updateOrientation();
    window.addEventListener('orientationchange', updateOrientation);
    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, [orientation]);
  useEffect(() => {
    dispatch(showLayout(false));
    const handleResize = () => {
      setWidth(window.innerWidth * calcWidthPercentage);
      setHeight(window.innerHeight * calcPercentage);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      dispatch(showLayout(true));
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const getSing = async () => {
    const mime = 'image/png';
    try {
      const base64 = refCanvas.current.getTrimmedCanvas().toDataURL(mime);
      const dataForm = { ...dataImages, mime, base64 };
      const response = await MediaService.firma(dataForm);
      console.log(response.data);
      if (response.data.id) {
        refCanvas.current.clear();
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {isMobile ? (
        <>
          {orientation == 'landscape-primary' || orientation == 'landscape-secondary' ? (
            <>
              <div className="flex item-center m-3">
                <ReactSignatureCanvas
                  ref={refCanvas}
                  canvasProps={{ width: width, height: height, className: 'sigCanvas border' }}
                />
              </div>
              <div className="flex justify-center my-1">
                <Button label="Guardar" color="success" onClick={() => getSing()} />
                <Button label="Limpiar" onClick={() => refCanvas.current.clear()} />
                <Button label="Regresar" onClick={() => navigate(-1)} />
              </div>
            </>
          ) : (
            <>
              <div>Please rotate your phone to view in landscape</div>
              <div>
                <img src="/images/rotate-screen.gif" />
              </div>
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Firma;
