import Card from 'Components/Card';
import { formatCurrency } from 'Utils/Functions';
import moment from 'moment';
import PropTypes from 'prop-types';
const EstatusCredito = (props) => {
  const { expediente } = props;
  const {
    montoAprovado,
    folioCredito,
    idStatusTag,
    montoDispercion,
    montoTotal,
    dispercion,
    dispersado,
    autorizacion,
    taza,
    plazo
  } = expediente;
  return (
    <>
      <Card title="Estatus del credito" className="col-span-2 lg:col-span-1">
        <div className="flex flex-col justify-between my-3">
          <div className="flex gap-3">
            <div>Folio Credito</div>
            <div className="font-semibold">{folioCredito}</div>
          </div>
          <div className="flex gap-3">
            <div>Estatus</div>
            <div className="font-semibold">{idStatusTag}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Monto Aprobado</div>
            <div className="font-semibold">{formatCurrency(montoAprovado)}</div>
          </div>
          <div>
            <div>Monto Dispersado</div>
            <div className="font-semibold">{formatCurrency(montoDispercion)}</div>
          </div>
          <div>
            <div>Monto Total</div>
            <div className="font-semibold">{formatCurrency(montoTotal)}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Fecha de Autorizacion</div>
            <div className="font-semibold">{moment(autorizacion).format('DD/MM/YYYY')}</div>
          </div>
          <div>
            <div>Fecha de Dispercion</div>
            <div className="font-semibold">
              {dispersado ? moment(dispercion).format('DD/MM/YYYY') : ''}
            </div>
          </div>
          <div>
            <div>Interes</div>
            <div className="font-semibold">{taza}</div>
          </div>
          <div>
            <div>Pagos</div>
            <div className="font-semibold">{plazo}</div>
          </div>
        </div>
      </Card>
    </>
  );
};
EstatusCredito.propTypes = {
  expediente: PropTypes.object.isRequired
};

export default EstatusCredito;
