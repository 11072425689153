import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import General from '../General';
import HistoricoSolicitudes from '../Historico-Solicitudes';
import Button from 'Components/Button';
import CreditoService from 'Services/Http/Credito.service';
import ModalComponent from 'Components/Modal';

const DetalleCliente = () => {
  const { id } = useParams();
  const [idPersona, setIdPersona] = useState('');
  const [loader, setLoader] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [kindModal, setKindModal] = useState('');
  const idProspecto = useSelector((state) => state.prospecto?.id);
  const navigate = useNavigate();
  useEffect(() => {
    orderData();
  }, [id]);
  const orderData = async () => {
    try {
      if (id in idProspecto) {
        await setIdPersona(idProspecto[id]);
      } else {
        navigate('/main');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const newApplication = async () => {
    setLoader(true);
    try {
      const response = await CreditoService.creditNewApplication(idPersona);
      console.log(response);
      setMessageModal('Se ha creado la solicitud');
      setTypeModal('success');
      setKindModal('notificacion');
      setStateModal(true);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
      setMessageModal('Ups, Ocurrio un erro valide e intente de nuevo');
      setKindModal('notificacion');
      setStateModal(true);
    }
  };
  const changeState = (state) => {
    setStateModal(state);
  };
  return (
    <>
      <div className="flex gap-3 my-3 justify-between">
        <ModalComponent
          show={stateModal}
          changeState={changeState}
          typeModal={kindModal}
          title={messageModal}
          stateModal={typeModal}
        />
        <Button
          label="Nuevo credito"
          color="success"
          iconprefix={['fa', 'add']}
          onClick={() => newApplication()}
          loading={loader}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        {idPersona ? (
          <>
            <General idPersona={idPersona} showDoctos={false} />
            <HistoricoSolicitudes idPersona={idPersona} />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default DetalleCliente;
