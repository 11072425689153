import HttpClient from './HttpClient';

const UsersService = {
  getAllUsers: (params) => {
    const path = 'users';
    return HttpClient.get(path, params, true);
  },
  createUsers: (params) => {
    const path = 'users';
    return HttpClient.post(path, params, true);
  },
  changeStatus: (id) => {
    const path = 'users/status';
    return HttpClient.put(path, { id }, true);
  },
  getUsersAssigns: () => {
    const path = 'users/assigns';
    return HttpClient.get(path, {}, true);
  },
  saveUserAssign: (params) => {
    const path = 'users/assigns';
    return HttpClient.post(path, params, true);
  },
  getRoles: () => {
    const path = 'users/role';
    return HttpClient.get(path, {}, true);
  },
  password: (params) => {
    const path = 'users/password';
    return HttpClient.post(path, params, true);
  }
};

export default UsersService;
