import Card from 'Components/Card';
import ClientesService from 'Services/Http/Clientes.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Ejecutivo from '../Ejecutivo';
import ImageComponent from 'Modules/Main/Validacion/Verificacion/Image';

const General = (props) => {
  const { idPersona, idExpediente, showDoctos = true } = props;
  const [persona, setPersona] = useState({});
  const [ejecutivo, setEjecutivo] = useState({});
  const getPerson = async () => {
    try {
      const response = await ClientesService.getPerson(idPersona);
      const data = response.data;
      setPersona(data);
      const dataEjecutivo = {
        creador: data.creador,
        asignado: data.asignado,
        fechaAsignacion: data.fechaAsignacion,
        created_at: data.created_at,
        canAssign: data.canAssign
      };
      setEjecutivo(dataEjecutivo);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getPerson();
  }, [idPersona]);
  return (
    <>
      <Card title="Informacion general" className="col-span-2 lg:col-span-1">
        <div className="flex flex-col justify-between my-3">
          <div className="flex gap-3">
            <div>ID persona</div>
            <div className="font-semibold">{persona.id}</div>
          </div>
          <div className="flex gap-3">
            <div>Nombre</div>
            <div className="font-semibold">{persona.nombreCompleto}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <div>Fecha de Nacimiento</div>
            <div className="font-semibold">{persona.fechaNacimiento}</div>
          </div>
          <div>
            <div>Edad</div>
            <div className="font-semibold">{persona.edad}</div>
          </div>
          <div>
            <div>Estado Civil</div>
            <div className="font-semibold">{persona.estadoCivil}</div>
          </div>
        </div>
        {showDoctos ? (
          <div className="flex flex-col md:flex-row justify-between my-3">
            <ImageComponent
              label="Ine Frontal"
              icon={['fa', 'id-card']}
              idPersona={idPersona}
              idExpediente={idExpediente}
              typePerson="CLIENTE"
              name="INE_FRONTAL"
            />
            <ImageComponent
              label="Ine Reverso"
              icon={['fa', 'money-check']}
              idPersona={idPersona}
              idExpediente={idExpediente}
              typePerson="CLIENTE"
              name="INE_REVERSO"
            />
          </div>
        ) : (
          ''
        )}
      </Card>
      <Ejecutivo idPersona={idPersona} ejecutivo={ejecutivo} />
    </>
  );
};

General.propTypes = {
  idPersona: PropTypes.string.isRequired,
  infoPersonal: PropTypes.objectOf(PropTypes.string),
  idExpediente: PropTypes.string,
  showDoctos: PropTypes.bool
};

export default General;
