import React from 'react';
import { Route } from 'react-router-dom';

const ExpedienteComponent = React.lazy(() => import('./Expediente'));
const ExpedienteLayout = React.lazy(() => import('./Expediente.layout'));
const ExpedienteRouter = (
  <Route path="expediente" element={<ExpedienteLayout />}>
    <Route
      path=":id/prospecto"
      element={<ExpedienteComponent solicitar={true} editable={true} />}
    />
    <Route
      path=":id/preautorizar"
      element={<ExpedienteComponent preautorizar={true} validar={true} editable={true} />}
    />
    <Route
      path=":id/renovacion"
      element={<ExpedienteComponent solicitar={true} editable={true} originRequired={false} />}
    />
  </Route>
);

export default ExpedienteRouter;
