import HttpClient from './HttpClient';

const ClientesService = {
  getAllClientes: (params) => {
    const path = 'people/clientes';
    return HttpClient.get(path, params, true);
  },
  getAllProspectos: (params) => {
    const path = 'people/prospecto';
    return HttpClient.get(path, params, true);
  },
  getAllSolicitantes: (params) => {
    const path = 'people/solicitantes';
    return HttpClient.get(path, params, true);
  },
  getPerson: (id) => {
    const path = 'people/id/' + id;
    return HttpClient.get(path, {}, true);
  },
  getPersonById: (id) => {
    const path = 'people/person/' + id;
    return HttpClient.get(path, {}, true);
  },
  createPerson: (typePerson, params) => {
    const path = 'people/' + typePerson;
    return HttpClient.post(path, params, true);
  },
  createCliente: (params) => {
    return ClientesService.createPerson('cliente', params);
  },
  createProspecto: (params) => {
    return ClientesService.createPerson('prospecto', params);
  },
  updateProspecto: (params) => {
    const path = 'people';
    return HttpClient.put(path, params, true);
  },
  createAval: (params) => {
    return ClientesService.createPerson('aval', params);
  },
  getEstadoCivil: () => {
    const path = 'people/status/marital';
    return HttpClient.get(path, {}, true);
  },
  saveCouple: (params) => {
    const path = 'people/marital/couple';
    return HttpClient.post(path, params, true);
  },
  getCouple: (id) => {
    const path = 'people/marital/couple/id/' + id;
    return HttpClient.get(path, {}, true);
  },
  getCoupleById: (id) => {
    const path = 'people/marital/couple/' + id;
    return HttpClient.get(path, {}, true);
  },
  saveEndorsement: (params) => {
    const path = 'people/endorsement';
    return HttpClient.post(path, params, true);
  },
  getEndorsement: (id) => {
    const path = 'people/endorsement/id/' + id;
    return HttpClient.get(path, {}, true);
  },
  getEndorsementById: (id) => {
    const path = 'people/endorsement/' + id;
    return HttpClient.get(path, {}, true);
  }
};

export default ClientesService;
