import HttpClient from './HttpClient';

const ReferenceService = {
  getTypeReference: () => {
    const path = 'reference/type';
    return HttpClient.get(path, {}, true);
  },
  save: (params) => {
    const path = 'reference';
    return HttpClient.post(path, params, true);
  },
  getReferences: (idPersona) => {
    const path = 'reference/id/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  getReferencesExpediente: (id) => {
    const path = 'reference/expediente/' + id;
    return HttpClient.get(path, {}, true);
  },
  deleteReference: (id) => {
    const path = 'reference/id/' + id;
    return HttpClient.delete(path, {}, true);
  }
};

export default ReferenceService;
