import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const NotificacionModal = (props) => {
  const { changeState, stateModal, title } = props;
  const [icon, setIcon] = useState([]);
  const [color, setColor] = useState('');
  useEffect(() => {
    checkState();
  }, [changeState, stateModal, title]);

  const checkState = () => {
    switch (stateModal) {
      case 'success':
        setIcon(['fa', 'check']);
        setColor('text-green-500');
        break;
      case 'error':
        setIcon(['fa', 'close']);
        setColor('text-red-500');
        break;
      case 'warning':
        setIcon(['fa', 'exclamation-triangle']);
        setColor('text-yellow-500');
        break;
      default:
        setIcon(['fa', 'question']);
        setColor('text-gray-500');
        break;
    }
  };

  return (
    <>
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        data-modal-hide="popup-modal"></button>
      <div className="p-4 md:p-5 text-center">
        <FontAwesomeIcon icon={icon} size={'3x'} className={color} />
        <h3 className="mb-5 text-lg text-gray-600">{title}</h3>
        <Button label="Cerrar" onClick={() => changeState(false)} />
      </div>
    </>
  );
};

NotificacionModal.propTypes = {
  changeState: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string
};

export default NotificacionModal;
