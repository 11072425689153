import axios from 'axios';
import store from '../../redux/store';
import { clearSession } from '../../redux/slices';
const urlBase = process.env.REACT_APP_BASE_PATH;

const client = axios.create({
  baseURL: urlBase,
  validateStatus: (status) => (status >= 200 && status < 300) || status === 500
});

const getSessionToken = (dispatch, session) => {
  const data = session('session');
  if (data?.session?.session?.access?.token) {
    return data.session.session.access.token;
  }
};

const closeSession = (dispatch) => {
  dispatch(clearSession());
};

client.interceptors.request.use(async (config) => {
  if (config.token) {
    const token = store.dispatch(getSessionToken);
    // eslint-disable-next-line no-param-reassign
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  if (config.media) {
    // eslint-disable-next-line no-param-reassign
    config.headers['Content-Type'] = 'multipart/form-data';
  }
  return config;
});

client.interceptors.response.use(
  async (config) => config,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(closeSession);
    }
    return Promise.reject(error.response);
  }
);

const HttpClient = {
  get: (path, params, token = false, media = false) => client.get(path, { params, token, media }),
  post: (path, params, token = false, media = false) => client.post(path, params, { token, media }),
  delete: (path, params, token = false, media = false) =>
    client.delete(path, { params, token, media }),
  put: (path, params, token = false, media = false) => client.put(path, params, { token, media })
};

export default HttpClient;
