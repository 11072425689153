import Card from 'Components/Card';
import OrigenService from 'Services/Http/Origen.service';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Origen = (props) => {
  const { idPersona } = props;
  const [loaderBar, setLoaderBar] = useState(false);
  const [origin, setOrigin] = useState({});
  const getDataOrigin = async () => {
    try {
      const response = await OrigenService.getDataOrigin(idPersona);
      console.log(response);
      if (response?.data?.origin) {
        setOrigin(response?.data?.origin);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getAllData = async () => {
    setLoaderBar(true);
    try {
      await getDataOrigin();
      setLoaderBar(false);
    } catch (e) {
      console.log(e);
      setLoaderBar(false);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <Card title="Origen del prospecto" className="col-span-2 xl:col-span-1" loader={loaderBar}>
      <div className="flex flex-col md:grid md:grid-flow-col md:grid-cols-2 md:flex-row gap-3 my-3 justify-stretch">
        <div>
          <div>Tipo de referencia: </div>
          <div className="font-semibold">{origin.tipoOrigen}</div>
        </div>
        <div>
          <div>ID Cliente: </div>
          <div className="font-semibold">{origin.recommend}</div>
        </div>
      </div>
      <div className="flex flex-col md:grid md:grid-flow-col md:grid-cols-2 md:flex-row gap-3 my-3 justify-stretch">
        <div>
          <div>Nombre: </div>
          <div className="font-semibold">{origin.nombre}</div>
        </div>
        <div>
          <div>Celular: </div>
          <div className="font-semibold">{origin.numero}</div>
        </div>
      </div>
    </Card>
  );
};

Origen.propTypes = {
  idPersona: PropTypes.string.isRequired,
  editable: PropTypes.bool
};

export default Origen;
