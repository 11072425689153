import Button from 'Components/Button';
import ModalComponent from 'Components/Modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreditoService from 'Services/Http/Credito.service';
import Card from 'Components/Card';
import { formatCurrency } from 'Utils/Functions';
import ControllerDatePicker from 'Components/ControllerDatePicker';
import { useForm } from 'react-hook-form';
import ControllerInput from 'Components/ControllerInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { AutorizarCreditoSchema } from 'Services/yup/form';

const Autorizacion = (props) => {
  const { idExpediente, idCredito, full = false, expediente = {} } = props;
  const navigate = useNavigate();
  const [stateModal, setStateModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [credito, setCredito] = useState(null);
  const [messageModal, setMessageModal] = useState('');
  const [modal, setModal] = useState('');
  const [loader, setLoader] = useState(false);
  const [montoTotal, setMontoTotal] = useState(0);
  const [actionName, setActionName] = useState('');

  useEffect(() => {
    setCredito(null);
    getCredito();
  }, []);

  useEffect(() => {
    if (credito) {
      const { taza } = expediente;
      const { montoSolicitado } = credito;
      const total = calculoMontoTotal(taza, montoSolicitado);
      setMontoTotal(total);
    }
  }, [credito]);

  const calculoMontoTotal = (taza, monto) => {
    return monto * taza;
  };

  const getCredito = async () => {
    try {
      const response = await CreditoService.creditoById(idCredito);
      setCredito(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      dispercion: '',
      montoAprovado: '',
      montoDispercion: ''
    },
    resolver: yupResolver(AutorizarCreditoSchema)
  });
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      console.log(value, name);
      if (name == 'montoAprovado') {
        const { taza } = expediente;
        const total = calculoMontoTotal(taza, value[name]);
        setMontoTotal(total);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const submit = async () => {
    setStateModal(false);
    setLoader(true);
    const dataForm = { ...getValues(), idExpediente };
    try {
      await CreditoService.autorizar(dataForm);
      setMessageModal('El credito a sido autorizado');
      setTypeModal('success');
      setModal('notificacion');
      setStateModal(true);
      setLoader(false);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      const message = e.data.message;
      setMessageModal(message);
      setTypeModal('error');
      setStateModal(true);
      setLoader(false);
    }
  };
  const actionOk = async (data, setLoader) => {
    switch (actionName) {
      case 'submit':
        submit();
        break;
      case 'rechazo':
        rechazoExpediente(data, setLoader);
        break;
      default:
        break;
    }
  };
  const rechazoExpediente = async (data, setLoader) => {
    setLoader(true);
    setStateModal(false);
    try {
      const dataForm = { idExpediente, ...data };
      await CreditoService.rechazoExpediente(dataForm);
      setLoader(false);
      setMessageModal('El credito a sido rechazado');
      setTypeModal('success');
      setModal('notificacion');
      setStateModal(true);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
    console.log(data);
  };
  const changeState = (state) => {
    setStateModal(state);
    if (success) {
      navigate(-1);
    }
  };
  const rechazo = () => {
    setActionName('rechazo');
    setMessageModal('La solicitud se ha enviado Autorizacion');
    setTypeModal('success');
    setModal('motivo');
    setStateModal(true);
  };
  const validarAutorizacion = () => {
    setActionName('submit');
    setMessageModal('Se autorizara el credito');
    setTypeModal('warning');
    setModal('decision');
    setStateModal(true);
  };
  return (
    <>
      <div className="col-span-1 lg:col-span-2 my-3">
        <ModalComponent
          show={stateModal}
          changeState={changeState}
          typeModal={modal}
          title={messageModal}
          stateModal={typeModal}
          actionOK={actionOk}
        />
        <Card title="Credito">
          <div className={full ? 'xl:w-full' : 'xl:w-1/4'}>
            <div className="grid grid-cols-1 my-3 gap-3">
              <div>
                <div>Capacidad de pago</div>
                <div>{formatCurrency(credito?.capacidadDePago)}</div>
              </div>
              <div>
                <div>Monto Solicitado</div>
                <div>{formatCurrency(credito?.montoSolicitado)}</div>
              </div>
              <div>
                <div>Monto Total</div>
                <div>{formatCurrency(montoTotal)}</div>
              </div>
              <div>
                <div>Monto Aprobado</div>
                <ControllerInput
                  control={control}
                  name="montoAprovado"
                  error={errors?.montoAprovado}
                  placeholder="Monto aprobado"
                  onlyNumbers={true}
                />
              </div>
              <div>
                <div>Monto a dispersar</div>
                <ControllerInput
                  control={control}
                  name="montoDispercion"
                  error={errors?.montoAprovado}
                  placeholder="Monto a dispersar"
                  onlyNumbers={true}
                />
              </div>
              <div>
                <div>Dispercion</div>
                <ControllerDatePicker
                  control={control}
                  name="dispercion"
                  error={errors?.dispercion}
                  placeholder="Fecha de dispercion"
                />
              </div>
              <div>
                <Button
                  label="Autorizar"
                  color="success"
                  onClick={handleSubmit(validarAutorizacion)}
                  loading={loader}
                />
              </div>
            </div>
          </div>
          <div>
            <Button label="Rechazar" color="error" onClick={() => rechazo()} loading={loader} />
          </div>
        </Card>
      </div>
    </>
  );
};

Autorizacion.propTypes = {
  expediente: PropTypes.object,
  idExpediente: PropTypes.number,
  idPersona: PropTypes.number,
  idCredito: PropTypes.number,
  full: PropTypes.bool
};

export default Autorizacion;
