import Label from 'Components/Label';
import CreditoService from 'Services/Http/Credito.service';
import { formatCurrency } from 'Utils/Functions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setExpediente, setIdExpediente } from '../../../../redux/slices';

const HistoricoSolicitudes = (props) => {
  const { idPersona } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  useEffect(() => {
    getHistorico();
  }, [idPersona]);
  const getHistorico = async () => {
    try {
      const response = await CreditoService.historico(idPersona);
      let data = response.data.map((item, index) => (
        <tr
          key={'creditos-row-' + index}
          className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer"
          onClick={() => (item.folioCredito ? toDetalleCredito(item.uuidCredito, item) : '')}>
          <td className="px-6 py-4">{item.id}</td>
          <td className="px-6 py-4">{item.folioCredito ? item.folioCredito : ''}</td>
          <td className="px-6 py-4">{formatCurrency(item.montoAprovado)}</td>
          <td className="px-6 py-4">{formatCurrency(item.montoDispercion)}</td>
          <td className="px-6 py-4">{formatCurrency(item.montoTotal)}</td>
          <td className="px-6 py-4">
            {item.dispersado ? moment(item.dispercion).format('DD/MM/YYYY') : ''}
          </td>
          <td className="px-6 py-4">
            {item.autorizacion ? moment(item.autorizacion).format('DD/MM/YYYY') : ''}
          </td>
          <td className="px-6 py-4">{item.idStatusTag}</td>
          <td className="px-6 py-4">{moment(item.created_at).format('DD/MM/YYYY')}</td>
        </tr>
      ));
      setRecords(data);
    } catch (e) {
      console.log(e);
    }
  };
  const toDetalleCredito = (uuid, item) => {
    const url = '/main/cliente/credito/' + uuid;
    const dataId = [];
    dataId[uuid] = item.id;
    dispatch(setIdExpediente(dataId));
    dispatch(setExpediente(item));
    goTo(url);
  };
  const goTo = (url) => {
    navigate(url);
  };
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg col-span-2">
        <Label text="Creditos" />
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="text-xs text-white uppercase bg-blue-500">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Credito
              </th>
              <th scope="col" className="px-6 py-3">
                Monto Aprobado
              </th>
              <th scope="col" className="px-6 py-3">
                Monto Dispersado
              </th>
              <th scope="col" className="px-6 py-3">
                Monto Total
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha dispercion
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha Autorizacion
              </th>
              <th scope="col" className="px-6 py-3">
                Estatus
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha solicitud
              </th>
            </tr>
          </thead>
          <tbody>{records}</tbody>
        </table>
      </div>
    </>
  );
};
HistoricoSolicitudes.propTypes = {
  idPersona: PropTypes.string.isRequired,
  infoPersonal: PropTypes.objectOf(PropTypes.string)
};
export default HistoricoSolicitudes;
