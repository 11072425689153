import PropTypes from 'prop-types';
const Label = (props) => {
  const { text } = props;
  return (
    <div>
      <div className="text-lg tracking-wide">
        <div className="text-yellow-600">{text}</div>
      </div>
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string
};

export default Label;
