import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Input from 'Components/Input';

const ControllerInput = (props) => {
  const {
    control,
    name,
    placeholder = '',
    disabled,
    onlyNumbers = false,
    onlyAlphanumeric = false
  } = props;
  const clearText = (text) => {
    let newText = alfaNumeric(text, onlyAlphanumeric);
    newText = clearNumber(newText, onlyNumbers);
    return newText;
  };
  const clearNumber = (text = '', onlyNumbers = false) => {
    let newText = text;
    if (onlyNumbers) {
      newText = text.target.value.replace(/[^0-9]/g, '');
    }
    return newText;
  };

  const alfaNumeric = (text = '', onlyAlfanumeric = false) => {
    let newText = text;
    if (onlyAlfanumeric) {
      newText = text.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    return newText;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            {...props}
            value={value}
            onChange={(val) => onChange(clearText(val))}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

ControllerInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onlyNumbers: PropTypes.bool,
  onlyAlphanumeric: PropTypes.bool
};

export default ControllerInput;
