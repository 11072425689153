import Label from 'Components/Label';
import ClientesService from 'Services/Http/Clientes.service';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setId } from '../../../../redux/slices';
import SearchBar from 'Components/SearchBar';

const ListarClienteComponente = () => {
  const [clientes, setClientes] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllClientes();
  }, []);

  const getAllClientes = async () => {
    setClientes([]);
    const response = await ClientesService.getAllClientes();
    createRows(response.data);
  };

  const expediente = (uuid, id) => {
    const url = '/main/cliente/detalle/' + uuid;
    const dataId = [];
    dataId[uuid] = id;
    dispatch(setId(dataId));
    goTo(url);
  };

  const goTo = (url) => {
    navigate(url);
  };
  const createRows = (rows) => {
    let data = rows.map((item, index) => (
      <tr
        key={'clientes-row-' + index}
        onClick={() => expediente(item.uuid, item.id)}
        className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer">
        <td className="px-6 py-4">{item.id}</td>
        <td className="px-6 py-4">{item.nombreCompleto}</td>
        <td className="px-6 py-4">{moment(item.created_at).format('DD/MM/YYYY')}</td>
        <td className="px-6 py-4">{item.creador}</td>
        <td className="px-6 py-4">{moment(item.fechaAsignacion).format('DD/MM/YYYY')}</td>
        <td className="px-6 py-4">{item.asignado}</td>
      </tr>
    ));
    setClientes(data);
  };
  const find = async (text) => {
    setClientes([]);
    const response = await ClientesService.getAllClientes(text);
    createRows(response.data);
  };
  return (
    <>
      <Label text={'Clientes'} />
      <div className="flex flex-col grid grid-cols-1 md:grid md:grid-flow-col md:grid-cols-2 md:flex-row gap-3 my-3 justify-stretch">
        <div className="flex order-2 md:order-1">
          <SearchBar find={find} reload={getAllClientes} />
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right">
          <thead className="text-xs text-white uppercase bg-blue-500">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Nombre completo
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha creacion
              </th>
              <th scope="col" className="px-6 py-3">
                Creado
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha asignacion
              </th>
              <th scope="col" className="px-6 py-3">
                Asignado
              </th>
            </tr>
          </thead>
          <tbody>{clientes}</tbody>
        </table>
      </div>
    </>
  );
};

export default ListarClienteComponente;
