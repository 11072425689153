import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const ControllerDatePicker = (props) => {
  const { control, name, error } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <DatePicker
              {...props}
              onChange={(date, dateString) => onChange(dateString)}
              value={value ? dayjs(value, 'YYYY-MM-DD') : ''}
              onBlur={onBlur}
              inputReadOnly={true}
              className="w-full"
              format={{
                format: 'YYYY-MM-DD',
                type: 'mask'
              }}
            />
            {error?.message && <span className="text-red-500">{error?.message}</span>}
          </>
        )}
      />
    </>
  );
};

ControllerDatePicker.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.object
};

export default ControllerDatePicker;
