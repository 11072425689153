import HttpClient from './HttpClient';

const EmpleoService = {
  getOccupation: () => {
    const path = 'employee/occupation';
    return HttpClient.get(path, {}, true);
  },
  saveEmployee: (params) => {
    const path = 'employee';
    return HttpClient.post(path, params, true);
  },
  getEmployee: (idPersona) => {
    const path = 'employee/id/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  getEmployeeById: (id) => {
    const path = 'employee/expediente/' + id;
    return HttpClient.get(path, {}, true);
  }
};

export default EmpleoService;
