import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/Button';
import NotificacionModal from './Notificacion';
import DecisionModal from './Decision';
import VerificacionModal from './Verificacion';
import RechazoModal from './Rechazo';
import MotivoModal from './Motivo';
import FirmaModal from './Firma';
import PasswordModal from './Password';
const ModalComponent = (props) => {
  const { show, changeState, typeModal } = props;
  const [showStyle, setShowStyle] = useState('');
  const [cmpModal, setCmpModal] = useState('');

  useEffect(() => {
    hiddenModal();
  }, [show]);

  const hiddenModal = () => {
    if (show) {
      setShowStyle('');
      setCmpModal(selectModal());
    } else {
      setShowStyle('hidden');
    }
  };

  const selectModal = () => {
    let modal;
    switch (typeModal) {
      case 'notificacion':
        modal = <NotificacionModal {...props} />;
        break;
      case 'decision':
        modal = <DecisionModal {...props} />;
        break;
      case 'verificacion':
        modal = <VerificacionModal {...props} />;
        break;
      case 'rechazo':
        modal = <RechazoModal {...props} />;
        break;
      case 'motivo':
        modal = <MotivoModal {...props} />;
        break;
      case 'firma':
        modal = <FirmaModal {...props} />;
        break;
      case 'password':
        modal = <PasswordModal {...props} />;
        break;
      default:
        modal = <Button label={'label'} onClick={changeState} />;
        break;
    }
    return modal;
  };
  return (
    <div
      id="popup-modal"
      className={
        showStyle +
        ' flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 bg-gray-900 bg-opacity-50 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-full'
      }>
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">{cmpModal}</div>
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  show: PropTypes.bool,
  changeState: PropTypes.func,
  actionOK: PropTypes.func,
  typeModal: PropTypes.string,
  title: PropTypes.string,
  stateModal: PropTypes.string,
  id: PropTypes.number,
  params: PropTypes.object
};

export default ModalComponent;
