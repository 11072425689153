import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import Select from 'Components/Select';

const ControllerSelect = (props) => {
  const { control, name, data = [], error, label } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {data.length > 0 ? (
              <>
                <Select
                  data={data}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  label={label}
                />
                {error?.message && <span className="text-red-500">{error?.message}</span>}
              </>
            ) : (
              ''
            )}
          </>
        )}
      />
    </>
  );
};

ControllerSelect.propTypes = {
  control: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.object
};

export default ControllerSelect;
