import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    session: null
  },
  reducers: {
    saveSession: (state, action) => {
      const { payload } = action;
      state.session = { ...state.session, ...payload };
    },
    clearSession: (state) => {
      state.session.access = null;
    }
  }
});

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    open: false,
    showLayout: true
  },
  reducers: {
    open: (state) => {
      state.open = !state.open;
    },
    showLayout: (state, action) => {
      const { payload } = action;
      state.showLayout = payload;
    }
  }
});

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    show: false
  },
  reducers: {
    showModal: (state) => {
      state.show = !state.show;
    }
  }
});

export const prospectoSlice = createSlice({
  name: 'prospecto',
  initialState: {
    id: {}
  },
  reducers: {
    setId: (state, action) => {
      const { payload } = action;
      state.id = { ...payload };
    },
    clearId: (state) => {
      state.id = {};
    }
  }
});

export const detalleSlice = createSlice({
  name: 'detalle',
  initialState: {
    id: {},
    expediente: {},
    firma: {}
  },
  reducers: {
    setIdExpediente: (state, action) => {
      const { payload } = action;
      state.id = { ...payload };
    },
    setExpediente: (state, action) => {
      const { payload } = action;
      state.expediente = { ...payload };
    },
    setFirma: (state, action) => {
      const { payload } = action;
      state.firma = { ...payload };
    },
    clearIdExpediente: (state) => {
      state.id = {};
    }
  }
});

export const { saveSession, clearSession } = sessionSlice.actions;
export const { open, showLayout } = menuSlice.actions;
export const { showModal } = modalSlice.actions;
export const { setId, clearId } = prospectoSlice.actions;
export const { setIdExpediente, clearIdExpediente, setExpediente, setFirma } = detalleSlice.actions;
