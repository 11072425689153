import Button from 'Components/Button';
import Label from 'Components/Label';
import ModalComponent from 'Components/Modal';
import CreditoService from 'Services/Http/Credito.service';
import PropTypes from 'prop-types';
import { useState } from 'react';

const Geolocalizacion = (props) => {
  const { idPersona, idExpediente, location = null, deleteItem = false } = props;
  const [userLocation, setUserLocation] = useState(location);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [userLocationPermision, setUserLocationPermision] = useState(true);
  const [stateModal, setStateModal] = useState(false);
  const getUserLocation = async () => {
    setLoader(true);
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const formData = { idExpediente, idPersona, latitude, longitude };
            try {
              const response = await CreditoService.geolocationSave(formData);
              setUserLocationPermision(true);
              setUserLocation(response.data);
              setLoader(false);
            } catch (e) {
              console.log(e);
              setLoader(false);
              setErrorMessage('Ocurrio un error al obtener la GEOLOCALIZACION');
            }
          },

          (error) => {
            console.error('Error get user location: ', error);
            setUserLocationPermision(false);
            setErrorMessage('Se requieren permiso para acceder a la GEOLOCALIZACION');
            setLoader(false);
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser');
        setErrorMessage('La GEOLOCALIZACION no es soportada por el navegador');
        setLoader(false);
      }
    } catch (e) {
      setErrorMessage('No tiene permisos para el acceso a la geolocalizacion');
      setLoader(false);
    }
  };
  const openMap = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${userLocation.latitude},${userLocation.longitude}`;
    window.open(url, '_blank');
  };
  const changeState = (state) => {
    setStateModal(state);
  };
  const deleteFile = async () => {
    try {
      const formData = { idExpediente, idPersona };
      await CreditoService.geolocationDelete(formData);
      setUserLocation(null);
      changeState(false);
    } catch (e) {
      console.log(e);
      changeState(false);
    }
  };
  return (
    <>
      <Label text={'GeoLocalizacion'} />
      <ModalComponent
        show={stateModal}
        changeState={changeState}
        typeModal={'decision'}
        title={'Se eliminara el archivo'}
        stateModal={'warning'}
        actionOK={deleteFile}
      />
      {userLocation ? (
        <>
          <Button iconprefix={['fa', 'map-location']} onClick={() => openMap()} />
          {deleteItem ? (
            <Button
              color="error"
              iconprefix={['fa', 'trash']}
              onClick={() => setStateModal(true)}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        <Button
          iconprefix={['fa', 'location-crosshairs']}
          onClick={() => getUserLocation()}
          loading={loader}
        />
      )}

      {userLocationPermision ? '' : <div className="text-red-600">{errorMessage}</div>}
    </>
  );
};

Geolocalizacion.propTypes = {
  idExpediente: PropTypes.number.isRequired,
  idPersona: PropTypes.number.isRequired,
  location: PropTypes.object,
  deleteItem: PropTypes.bool
};

export default Geolocalizacion;
