import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from 'Components/Label';
import ModalComponent from 'Components/Modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Telefono = (props) => {
  const { phone = [] } = props;
  const [listPhones, setListPhones] = useState();
  const [stateModal, setStateModal] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [titleModal, setTitleModal] = useState('');
  const [params, setParams] = useState({});

  const orderData = async () => {
    if (phone?.length > 0) {
      const data = phone.map((item, index) => {
        return (
          <tr
            key={'clientes-row-' + index}
            className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer">
            <td className="px-6 py-4">{item.tipoTelefono}</td>
            <td className="px-6 py-4">{item.movil}</td>
            <td className="px-6 py-4">
              {item.valido ? (
                <FontAwesomeIcon icon={['fa', 'check']} className="text-green-500" />
              ) : (
                <FontAwesomeIcon
                  icon={['fa', 'comment']}
                  onClick={() => openModalSms(item)}
                  className="text-green-500"
                />
              )}
            </td>
          </tr>
        );
      });
      setListPhones(data);
    }
  };
  const changeState = (state) => {
    setStateModal(state);
  };
  useEffect(() => {
    orderData();
  }, [phone]);
  const openModalSms = (item) => {
    setParams({ ...item });
    setTypeModal('verificacion');
    setTitleModal('Verificacion');
    setStateModal(true);
  };
  return (
    <>
      <Label text="Telefono" />
      <div className="flex flex-col md:flex-row gap-3 my-3">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-black">
            <thead className="text-xs text-white uppercase bg-blue-500">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Tipo
                </th>
                <th scope="col" className="px-6 py-3">
                  Telefono
                </th>
                <th scope="col" className="px-6 py-3">
                  validar
                </th>
              </tr>
            </thead>
            <tbody>{listPhones}</tbody>
          </table>
        </div>
      </div>
      <ModalComponent
        show={stateModal}
        changeState={changeState}
        typeModal={typeModal}
        title={titleModal}
        stateModal={'warning'}
        params={params}
      />
    </>
  );
};

Telefono.propTypes = {
  type: PropTypes.string,
  phone: PropTypes.arrayOf(PropTypes.object)
};

export default Telefono;
