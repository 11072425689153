import PropTypes from 'prop-types';
import { useId, useState } from 'react';
const Select = (props) => {
  const { data = [], onChange, onBlur, value, label = 'Seleccionar opcion' } = props;
  const [options, setOptions] = useState([]);
  const dinamicId = useId('slOpt');

  useState(async () => {
    const opts = data.map((item, index) => (
      <option key={'slOpt' + dinamicId + index} value={item.value}>
        {item.label}
      </option>
    ));
    opts.unshift(
      <option key={'slOpt' + dinamicId} value="">
        {label}
      </option>
    );
    setOptions(opts);
  }, [data]);
  return (
    <select value={value} onChange={onChange} onBlur={onBlur} className="input-base">
      {options}
    </select>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
};
export default Select;
