import HttpClient from './HttpClient';

const DashboardService = {
  numeralia: () => {
    const path = 'dashboard';
    return HttpClient.get(path, {}, true);
  }
};

export default DashboardService;
