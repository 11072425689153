import { Route } from 'react-router-dom';
import Solicitudes from './Solicitudes';
import ListarSolicitudesComponente from './Listar-Solicitudes';

const SolicitudesRouter = (
  <Route path="solicitud" element={<Solicitudes />}>
    <Route path="" element={<ListarSolicitudesComponente />} />
  </Route>
);

export default SolicitudesRouter;
