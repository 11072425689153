import ButtonBack from 'Components/ButtonBack';
import Label from 'Components/Label';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import General from '../General';
import EstatusCredito from './Estatus-Credito';
import Domicilio from '../Domicilio';
import Telefono from '../Telefonos';
import Aval from '../Aval';
import Referencias from '../Referencias';
import Documentos from '../Documentos';
import Empleo from '../Empleo';
import Origen from '../Origen';
import Conyuge from '../Conyuge';

const DetalleCredito = () => {
  const { id } = useParams();
  const [idExpediente, setIdExpediente] = useState('');
  const expedienteMem = useSelector((state) => state.detalle?.id);
  const expediente = useSelector((state) => state.detalle?.expediente);
  const navigate = useNavigate();
  useEffect(() => {
    orderData();
  }, [id]);
  const orderData = async () => {
    try {
      if (id in expedienteMem) {
        await setIdExpediente(expedienteMem[id]);
      } else {
        navigate('/main');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Label text="Detalle de Credito" />
      <ButtonBack />
      <div className="my-3 grid grid-cols-1 lg:grid-cols-2 gap-2">
        {idExpediente ? (
          <>
            <General idPersona={expediente.idPersona} idExpediente={expediente.id} />
            <EstatusCredito expediente={expediente} />
            {expediente.auth ? <Documentos idExpediente={expediente.id} /> : ''}
            <Empleo idExpediente={expediente.idEmpleo} />
            <Telefono idPersona={expediente.idPersona} idTelefono={expediente.idTelefonos} />
            <Aval
              id={expediente.idAval}
              idExpediente={expediente.id}
              idPersona={expediente.idPersonaAval}
            />
            <Domicilio
              id={expediente.idDomicilio}
              location={{ latitude: expediente.latitude, longitude: expediente.longitude }}
              idExpediente={expediente.id}
              idPersona={expediente.idPersona}
            />
            <Domicilio
              id={expediente.idAvalDomicilio}
              aval={true}
              idExpediente={expediente.id}
              idPersona={expediente.idPersonaAval}
            />
            <Referencias idExpediente={expediente.id} />
            <Origen idPersona={expediente.idPersona} />
            <Conyuge idConyuge={expediente.idConyuge} />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default DetalleCredito;
