import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import Card from 'Components/Card';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

const FirmaModal = (props) => {
  const { changeState } = props;
  const padsign = useRef();
  return (
    <Card>
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        data-modal-hide="popup-modal"
        onClick={() => changeState(false)}>
        <FontAwesomeIcon icon={['fa', 'close']} size="2x" />
      </button>
      <div className="text-center bg-gray-100 h-full w-full">
        <ReactSignatureCanvas penColor="blue" ref={padsign} />
      </div>
      <div className="text-center bg-gray-100">
        <Button label="Guardar" color="success" />
        <Button label="Limpiar" onClick={() => padsign.current.clear()} />
        <Button label="Cancelar" onClick={() => changeState(false)} />
      </div>
    </Card>
  );
};

FirmaModal.propTypes = {
  changeState: PropTypes.func,
  actionOK: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.object,
  id: PropTypes.string
};

export default FirmaModal;
