import Card from 'Components/Card';
import PropTypes from 'prop-types';
import Descargable from 'Components/Descargable';

const Documentos = (props) => {
  const { idExpediente } = props;
  return (
    <>
      <Card title={'Documentos del credito'} className="col-span-2 lg:col-span-1">
        <div className="flex flex-col gap-3 my-3 justify-stretch">
          <Descargable id={idExpediente} typeMime={false} typeFile="pdf" file="amortizacion" />
          <Descargable id={idExpediente} typeMime={false} typeFile="pdf" file="pagare" />
          <Descargable id={idExpediente} typeMime={false} typeFile="pdf" file="contrato" />
        </div>
      </Card>
    </>
  );
};
Documentos.propTypes = {
  idExpediente: PropTypes.string
};

export default Documentos;
