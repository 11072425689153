import HttpClient from './HttpClient';

const MediaService = {
  documentos: (images) => {
    const path = 'media/document';
    return HttpClient.post(path, images, true, true);
  },
  firma: (images) => {
    const path = 'media/signature';
    return HttpClient.post(path, images, true, true);
  },
  getDocumento: (idExpediente, idPersona, document) => {
    const path = 'media/document/' + idExpediente + '/' + idPersona + '/' + document;
    return HttpClient.get(path, {}, true);
  },
  deleteDocumento: (idExpediente, idPersona, document) => {
    const path = 'media/document/' + idExpediente + '/' + idPersona + '/' + document;
    return HttpClient.delete(path, {}, true);
  },
  amortizacion: (id) => {
    const path = 'media/pdf/amortizacion/' + id;
    return HttpClient.get(path, {}, true);
  },
  pagare: (id) => {
    const path = 'media/pdf/pagare/' + id;
    return HttpClient.get(path, {}, true);
  },
  contrato: (id) => {
    const path = 'media/pdf/contrato/' + id;
    return HttpClient.get(path, {}, true);
  },
  getLink: (id, documento) => {
    const path = 'media/pdf/link/' + id + '/' + documento;
    return HttpClient.get(path, {}, true);
  }
};

export default MediaService;
