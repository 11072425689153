import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const ControllerTextArea = (props) => {
  const { control, name, placeholder = '', disabled, error = null } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <textarea
              rows="3"
              className="flex block w-full"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled}
              placeholder={placeholder}></textarea>
            {error?.message && <span className="text-red-500">{error?.message}</span>}
          </>
        )}
      />
    </>
  );
};

ControllerTextArea.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object
};

export default ControllerTextArea;
