import Card from 'Components/Card';
import ClientesService from 'Services/Http/Clientes.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Conyuge = (props) => {
  const { idConyuge } = props;
  const [loaderBar, setLoaderBar] = useState(false);
  const [dataConyuge, setDataConyuge] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = async () => {
    setLoaderBar(true);
    try {
      await getCouple();
      setLoaderBar(false);
    } catch (e) {
      console.log(e);
      setLoaderBar(false);
    }
  };

  const getCouple = async () => {
    setLoaderBar(true);
    try {
      const response = await ClientesService.getCoupleById(idConyuge);
      const dataCouple = response.data;
      setDataConyuge(dataCouple);
      setLoaderBar(false);
    } catch (e) {
      console.log(e);
      setLoaderBar(false);
    }
  };
  return (
    <Card title="Conyuge" className="col-span-2 xl:col-span-1" loader={loaderBar}>
      <div className="flex flex-col md:grid md:grid-flow-col md:grid-cols-1 lg:grid-cols-1 md:flex-row gap-3 my-3 justify-stretch">
        <div>
          <div>Nombre: </div>
          <div className="font-semibold">{dataConyuge.nombreCompleto}</div>
        </div>
      </div>
      <div className="flex flex-col md:grid md:grid-flow-col md:grid-cols-2 lg:grid-cols-2 md:flex-row gap-3 my-3 justify-stretch">
        <div>
          <div>Ocupacion: </div>
          <div className="font-semibold">{dataConyuge.idOcupacion}</div>
        </div>
        <div>
          <div>Celular: </div>
          <div className="font-semibold">{dataConyuge.telefono}</div>
        </div>
      </div>
    </Card>
  );
};

Conyuge.propTypes = {
  idConyuge: PropTypes.string.isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
  editable: PropTypes.bool
};

export default Conyuge;
