import { Route } from 'react-router-dom';
import Preautorizar from './Preautorizar';
import ListarSolicitantesComponente from './Listar-Solicitantes';

const PreautorizarRouter = (
  <Route path="preautorizar" element={<Preautorizar />}>
    <Route path="" element={<ListarSolicitantesComponente />} />
  </Route>
);

export default PreautorizarRouter;
