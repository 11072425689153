import { Route } from 'react-router-dom';
import Firma from './Firma';

const FirmaRouter = (
  <Route path="firma">
    <Route path=":id" element={<Firma />} />
  </Route>
);

export default FirmaRouter;
