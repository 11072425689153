import { Route } from 'react-router-dom';
import Validacion from './Validacion';
import ListarValidacionesComponente from './Listar-Validaciones';
import VerificacionComponent from './Verificacion';

const ValidacionRouter = (
  <Route path="validacion" element={<Validacion />}>
    <Route path="" element={<ListarValidacionesComponente />} />
    <Route
      path=":id/verificacion"
      element={
        <VerificacionComponent validacion={true} title="Validacion de datos" editable={true} />
      }
    />
    <Route
      path=":id/autorizacion"
      element={
        <VerificacionComponent
          autorizacion={true}
          title="Autorizacion de credito"
          editable={true}
        />
      }
    />
    <Route
      path=":id/autorizado"
      element={<VerificacionComponent validacionCredito={true} title="Estatus del Credito" />}
    />
  </Route>
);

export default ValidacionRouter;
