import HttpClient from './HttpClient';

const PhoneService = {
  getTypePhone: () => {
    const path = 'phone/type';
    return HttpClient.get(path, {}, true);
  },
  getPhones: (id) => {
    const path = 'phone/' + id;
    return HttpClient.get(path, {}, true);
  },
  getPhonesById: (id, idPersona) => {
    const path = 'phone/id/' + id + '/persona/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  deletePhones: (idPersona, idTelefono) => {
    const path = 'phone/' + idPersona + '/' + idTelefono;
    return HttpClient.delete(path, {}, true);
  },
  addPhone: (params) => {
    const path = 'phone';
    return HttpClient.post(path, params, true);
  }
};

export default PhoneService;
