import HttpClient from './HttpClient';

const DomicilioService = {
  code: (code) => {
    const path = 'address/postal/' + code;
    return HttpClient.get(path, {}, true);
  },
  estados: () => {
    const path = 'address/postal/estados';
    return HttpClient.get(path, {}, true);
  },
  municipios: (estado) => {
    const path = 'address/postal/municipio/' + estado;
    return HttpClient.get(path, {}, true);
  },
  typeHome: () => {
    const path = 'address/type-home';
    return HttpClient.get(path, {}, true);
  },
  save: (params) => {
    const path = 'address';
    return HttpClient.post(path, params, true);
  },
  getDomicilio: (id) => {
    const path = 'address/persona/' + id;
    return HttpClient.get(path, {}, true);
  },
  getDomicilioById: (id) => {
    const path = 'address/id/' + id;
    return HttpClient.get(path, {}, true);
  }
};

export default DomicilioService;
