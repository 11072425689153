import Card from 'Components/Card';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReferenceService from 'Services/Http/Referencias';

const Referencias = (props) => {
  const { idExpediente } = props;
  const [referencias, setReferencias] = useState([]);
  const [loaderBar, setLoaderBar] = useState(false);
  const getReferences = async () => {
    setReferencias([]);
    try {
      const responce = await ReferenceService.getReferencesExpediente(idExpediente);
      const dataReferencias = responce.data.map((item, index) => {
        return (
          <tr
            key={'clientes-row-' + index}
            className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer">
            <td className="px-6 py-4">{item.descripcionParentesco}</td>
            <td className="px-6 py-4">{item.nombreCompleto}</td>
            <td className="px-6 py-4">{item.celular}</td>
            <td className="px-6 py-4">{item.fijo}</td>
            <td className="px-6 py-4">{item.aniosDeConocer}</td>
          </tr>
        );
      });
      setReferencias(dataReferencias);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllData = async () => {
    setLoaderBar(true);
    try {
      await getReferences();
      setLoaderBar(false);
    } catch (e) {
      console.log(e);
      setLoaderBar(false);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <Card
        title="Referencias del prospecto"
        loader={loaderBar}
        className="col-span-2 xl:col-span-1">
        <div className="flex flex-col md:flex-row gap-3 my-3">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-black">
              <thead className="text-xs text-white uppercase bg-blue-500">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tipo de referencia
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nombre
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Celular
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Fijo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tiempo de conocerse
                  </th>
                </tr>
              </thead>
              <tbody>{referencias}</tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};

Referencias.propTypes = {
  idExpediente: PropTypes.string,
  idPersona: PropTypes.string,
  validar: PropTypes.bool
};

export default Referencias;
