import Card from 'Components/Card';
import PhoneService from 'Services/Http/Phone.service';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Telefono = (props) => {
  const { idTelefono, idPersona } = props;
  const [loaderBar, setLoaderBar] = useState(false);
  const [telefonos, setTelefonos] = useState([]);
  const getAllData = async () => {
    setLoaderBar(true);
    try {
      await getPhones();
      setLoaderBar(false);
    } catch (e) {
      console.log(e);
      setLoaderBar(false);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  const getPhones = async () => {
    setTelefonos([]);
    try {
      const response = await PhoneService.getPhonesById(idTelefono, idPersona);
      const dataTelefono = response.data.map((item, index) => {
        return (
          <tr
            key={'clientes-row-' + index}
            className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer">
            <td className="px-6 py-4">{item.descripcionTipoTelefono}</td>
            <td className="px-6 py-4">{item.telefono}</td>
          </tr>
        );
      });
      setTelefonos(dataTelefono);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Card
        title="Telefonos de contacto del cliente"
        loader={loaderBar}
        className="col-span-2  xl:col-span-1">
        <div className="flex flex-col md:flex-row gap-3 my-3">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-black">
              <thead className="text-xs text-white uppercase bg-blue-500">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tipo de telefono
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Numero
                  </th>
                </tr>
              </thead>
              <tbody>{telefonos}</tbody>
            </table>
          </div>
        </div>
      </Card>
    </>
  );
};

Telefono.propTypes = {
  idPersona: PropTypes.string.isRequired,
  idTelefono: PropTypes.string.isRequired
};

export default Telefono;
