import React from 'react';
import { Route } from 'react-router-dom';
import AdministracionRouter from './Administracion/Administracion.router';
import ProspectosRouter from './Prospectos/Prospectos.router';
import ExpedienteRouter from './Expediente/Expediente.router';
import PreautorizarRouter from './Preautorizar/Preautorizar.router';
import ValidacionRouter from './Validacion/Validacion.router';
import AutorizarRouter from './Autorizar/Autorizar.router';
import CreditosRouter from './Creditos/Creditos.router';
import ClientesRouter from './Clientes/Clientes.router';
import SolicitudesRouter from './Solicitudes/Solicitudes.router';
import FirmaRouter from './Firma/Firma.router';
import DescargasRouter from './Descargas/Descargas.router';
import DashboardRouter from './Dashboard/Dashboard.router';

const MainComponent = React.lazy(() => import('./Main'));
const MainRouter = (
  <Route path="/main" element={<MainComponent />}>
    {AdministracionRouter}
    {ProspectosRouter}
    {PreautorizarRouter}
    {ValidacionRouter}
    {AutorizarRouter}
    {ExpedienteRouter}
    {CreditosRouter}
    {ClientesRouter}
    {SolicitudesRouter}
    {FirmaRouter}
    {DescargasRouter}
    {DashboardRouter}
  </Route>
);

export default MainRouter;
