import Button from 'Components/Button';
import ModalComponent from 'Components/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CreditoService from 'Services/Http/Credito.service';

const SolicitarAutorizacion = (props) => {
  const { idPersona, idExpediente } = props;
  const navigate = useNavigate();
  const [stateModal, setStateModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [loader, setLoader] = useState(false);

  const submit = async () => {
    setLoader(true);
    try {
      await CreditoService.autorizacion(idPersona, idExpediente);
      setMessageModal('La solicitud se a enviado Autorizacion');
      setTypeModal('success');
      setStateModal(true);
      setLoader(false);
      setSuccess(true);
    } catch (e) {
      console.log(e);
      const message = e.data.message + ' \n ' + e.data.modulos.join(', ');
      setMessageModal(message);
      setTypeModal('error');
      setStateModal(true);
      setLoader(false);
    }
  };
  const changeState = (state) => {
    setStateModal(state);
    if (success) {
      navigate(-1);
    }
  };
  return (
    <>
      <div className="col-span-2 my-3">
        <ModalComponent
          show={stateModal}
          changeState={changeState}
          typeModal="notificacion"
          title={messageModal}
          stateModal={typeModal}
        />
        <Button
          label="Solicitar Autorizacion"
          color="success"
          onClick={() => submit()}
          loading={loader}
        />
      </div>
    </>
  );
};

SolicitarAutorizacion.propTypes = {
  idExpediente: PropTypes.number,
  idPersona: PropTypes.number
};

export default SolicitarAutorizacion;
