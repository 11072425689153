import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import ControllerInput from 'Components/ControllerInput';
import UsersService from 'Services/Http/Users.service';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { CambioPasswordSchema } from 'Services/yup/form';

const PasswordModal = (props) => {
  const { changeState, params } = props;
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState('');
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(CambioPasswordSchema)
  });

  const submit = async (data) => {
    try {
      const form = { ...data, id: params.id };
      const response = await UsersService.password(form);
      setMessage(response?.data?.message);
      setLoader(false);
      reset();
    } catch (e) {
      console.log(e);
      setMessage(e?.data?.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    console.log(params);
    reset();
  }, []);

  return (
    <div className="p-5">
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        onClick={() => changeState(false)}
        data-modal-hide="popup-modal">
        <FontAwesomeIcon icon={['fa', 'close']} size="2x" />
      </button>
      <div className="text-center">
        <h3 className="text-lg font-normal m-2">La contraseña sera actualizada para el usuario:</h3>
        <div className="text-xl font-normal m-2">{params.nombreCompleto}</div>
      </div>
      <form autoComplete="false">
        <div className=" w-full flex flex-row justify-center text-blue-600">{message}</div>
        <div className="w-full lg:w-1/2 py-5 space-y-4 flex flex-col">
          <div className="w-full space-y-4 flex flex-col">
            <ControllerInput
              control={control}
              name="password"
              error={errors?.password}
              type="password"
              placeholder="Contrase&ntilde;a"
            />
            <ControllerInput
              control={control}
              name="passwordConfirm"
              error={errors?.passwordConfirm}
              type="password"
              placeholder="Confirmar contrase&ntilde;a"
            />
          </div>
        </div>
        <div className=" w-full flex flex-row justify-center">
          <Button
            onClick={handleSubmit(submit)}
            label="Guardar"
            color="primary"
            type="button"
            loading={loader}
          />
          <Button onClick={() => reset()} label="Limpiar" type="button" />
          <Button onClick={() => changeState(false)} label="Cerrar" type="button" />
        </div>
      </form>
    </div>
  );
};

PasswordModal.propTypes = {
  params: PropTypes.object,
  changeState: PropTypes.func,
  actionOK: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string
};

export default PasswordModal;
