import React from 'react';
import { Route } from 'react-router-dom';

const CreateUserComponent = React.lazy(() => import('./Create-User'));
const UserListComponent = React.lazy(() => import('./List-User'));
const UsersComponent = React.lazy(() => import('./Users'));
const UsersRouter = (
  <Route path="users" element={<UsersComponent />}>
    <Route path="" element={<UserListComponent />} />
    <Route path="create" element={<CreateUserComponent />} />
  </Route>
);

export default UsersRouter;
