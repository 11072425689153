import HttpClient from './HttpClient';

const SmsService = {
  sendSms: (params, service) => {
    const path = 'sms/' + service;
    return HttpClient.post(path, params, true);
  },
  validarSms: (params, service) => {
    const path = 'sms/validate/' + service;
    return HttpClient.post(path, params, true);
  },
  getSms: () => {
    const path = 'sms/last/otps';
    return HttpClient.get(path, {}, true);
  }
};

export default SmsService;
