import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import AuthRouter from './Modules/Auth/Auth.router';
import MainRouter from './Modules/Main/Main.router';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {AuthRouter}
        {MainRouter}
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
