import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { detalleSlice, menuSlice, modalSlice, prospectoSlice, sessionSlice } from './slices';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { encryptTransform } from 'redux-persist-transform-encrypt';

let arrayTransform = [];

if (process.env.REACT_APP_ENVIRONMENT == 'production') {
  arrayTransform = [
    encryptTransform({
      secretKey: 'keys',
      onError: function (error) {
        // Handle the error.
        console.log(error);
      }
    })
  ];
}

const persistConfig = {
  key: 'app-main',
  version: 1,
  storage,
  timeout: null,
  stateReconciler: autoMergeLevel2,
  debug: true,
  transforms: arrayTransform,
  blacklist: ['menu', 'modal']
};

const reducers = combineReducers({
  session: sessionSlice.reducer,
  menu: menuSlice.reducer,
  modal: modalSlice.reducer,
  prospecto: prospectoSlice.reducer,
  detalle: detalleSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, FLUSH, REHYDRATE, PAUSE, PURGE, REGISTER]
      }
    })
});
