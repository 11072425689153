import PropTypes from 'prop-types';
import { useEffect, useId, useState } from 'react';
import { BUTTON_COLORS } from '../../Utils/Theme';
import Spinner from '../Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tooltip';

const Button = (props) => {
  const {
    label = '',
    color = 'secondary',
    loading = false,
    iconprefix = [],
    iconsubfix = '',
    tooltip = '',
    iconsize = '1x',
    disabled = false
  } = props;
  const [styleColor, setStyleColor] = useState('');
  const [themeColor, setThemeColor] = useState('');
  const [loaderStyle, setLoaderStyle] = useState('');
  const dinamicId = useId('btn');

  useEffect(() => {
    selectColor();
    activeLoader();
  }, [loading, color]);

  const activeLoader = () => {
    if (loading) {
      setLoaderStyle('bg-opacity-30');
    } else {
      setLoaderStyle('');
    }
  };

  const selectColor = () => {
    let baseColor;
    let textColor;
    switch (color) {
      case BUTTON_COLORS.PRIMARY:
        baseColor = 'bg-blue-500 active:bg-blue-700 focus:bg-blue-600';
        textColor = 'text-white';
        setThemeColor('blue');
        break;
      case BUTTON_COLORS.SUCCESS:
        baseColor = 'bg-green-500 active:bg-green-700 focus:bg-green-600';
        textColor = 'text-white';
        setThemeColor('green');
        break;
      case BUTTON_COLORS.ERROR:
        baseColor = 'bg-red-500 active:bg-red-700 focus:bg-red-600';
        textColor = 'text-white';
        setThemeColor('red');
        break;
      case BUTTON_COLORS.ACCENT:
        baseColor = 'bg-blue-300 active:bg-blue-500 focus:bg-blue-400';
        textColor = 'text-white';
        setThemeColor('blue');
        break;

      case BUTTON_COLORS.TRANSPARENT:
        baseColor = 'bg-transparent';
        textColor = 'text-white';
        setThemeColor('white');
        break;

      default:
        baseColor = 'bg-gray-300 active:bg-gray-600 focus:bg-gray-400';
        textColor = 'text-black';
        setThemeColor('black');
        break;
    }
    setStyleColor(baseColor + ' ' + textColor);
  };
  return (
    <>
      <button
        {...props}
        disabled={disabled || loading}
        className={
          'h-9 px-3 mx-1 ' +
          loaderStyle +
          ' ' +
          styleColor +
          ' transition duration-500 rounded-md disabled:opacity-50'
        }
        data-tooltip-id={'btn-' + dinamicId}
        data-tooltip-content={tooltip}>
        <div className="flex justify-center content-center items-center gap-2">
          {iconprefix.length > 0 ? (
            <FontAwesomeIcon icon={iconprefix} className={themeColor} size={iconsize} />
          ) : (
            ''
          )}
          <span className="flex gap-2 justify-self-auto">
            {label}
            {loading ? <Spinner color={themeColor} /> : ''}
          </span>
          {iconsubfix.length > 0 ? (
            <FontAwesomeIcon icon={iconsubfix} className={'rtl ' + themeColor} size={iconsize} />
          ) : (
            ''
          )}
        </div>
      </button>
      <Tooltip id={'btn-' + dinamicId} />
    </>
  );
};

Button.propTypes = {
  /**
   * Button label
   */
  label: PropTypes.string,
  color: PropTypes.string,
  loading: PropTypes.bool,
  iconprefix: PropTypes.arrayOf(PropTypes.string),
  iconsubfix: PropTypes.arrayOf(PropTypes.string),
  iconsize: PropTypes.string,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
