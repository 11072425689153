import Button from 'Components/Button';
import ControllerInput from 'Components/ControllerInput';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FindSchema } from 'Services/yup/form';

const SearchBar = (props) => {
  const { find, finding = false, reload = null } = props;
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      text: ''
    },
    resolver: yupResolver(FindSchema)
  });

  const refresh = () => {
    reset();
    if (reload) {
      reload();
    }
  };

  return (
    <>
      <div className="w-full xl:w-1/2 flex flex-col md:flex-row gap-3">
        <ControllerInput control={control} name="text" type="text" placeholder="" />
        <Button
          iconprefix={['fa', 'search']}
          label="buscar"
          onClick={handleSubmit(find)}
          loading={finding}
        />
        <Button iconprefix={['fa', 'arrows-rotate']} onClick={() => refresh()} />
      </div>
    </>
  );
};

SearchBar.propTypes = {
  find: PropTypes.func,
  reload: PropTypes.func,
  finding: PropTypes.bool
};

export default SearchBar;
