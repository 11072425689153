export const BUTTON_COLORS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACCENT: 'accent',
  WARN: 'warn',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  TRASPARENT: 'trasparent'
};
