import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import Card from 'Components/Card';
import ControllerTextArea from 'Components/ControllerTextArea';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearId } from '../../redux/slices';
import CreditoService from 'Services/Http/Credito.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { RechazoSchema } from 'Services/yup/form';
import { useNavigate } from 'react-router-dom';

const RechazoModal = (props) => {
  const { changeState, params = {}, id } = props;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(params);
    setMessage('');
  }, []);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      motivo: ''
    },
    resolver: yupResolver(RechazoSchema)
  });

  const submit = async (data) => {
    setLoader(true);
    const dataForm = { idPersona: id, ...data };
    try {
      const response = await CreditoService.rechazo(dataForm);
      console.log(response);
      setLoader(false);
      dispatch(clearId());
      navigate(-1);
      changeState(false);
    } catch (e) {
      console.log(e);
      setMessage('Ups, Ocurrio un error');
      setLoader(false);
    }
  };

  return (
    <Card>
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        data-modal-hide="popup-modal"
        onClick={() => changeState(false)}>
        <FontAwesomeIcon icon={['fa', 'close']} size="2x" />
      </button>
      <div className="text-center bg-gray-100">
        <FontAwesomeIcon
          icon={['fa', 'triangle-exclamation']}
          size="3x"
          className="text-yellow-300"
        />
        <h3 className="text-lg font-normal m-2">¿Cual es el motivo del rechazo?</h3>
        <div className="m-5">
          <ControllerTextArea
            control={control}
            name="motivo"
            placeholder="Motivo"
            error={errors?.motivo}
          />
        </div>
        {message ? <div className="m-5 text-red-400">{message}</div> : ''}
        <Button label="Rechazar" color="error" onClick={handleSubmit(submit)} loading={loader} />
        <Button label="Cancelar" onClick={() => changeState(false)} />
      </div>
    </Card>
  );
};

RechazoModal.propTypes = {
  changeState: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.object,
  id: PropTypes.string
};

export default RechazoModal;
