import Button from 'Components/Button';
import Label from 'Components/Label';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setFirma } from '../../../../../redux/slices';
import MediaService from 'Services/Http/Media.service';
import { useEffect, useState } from 'react';
import ModalComponent from 'Components/Modal';

const Firma = (props) => {
  const { idPersona, idExpediente, typePerson, name, deleteItem = false } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [document, setDocument] = useState({});
  const [stateModal, setStateModal] = useState(false);
  const activeFirma = () => {
    const url = '/main/firma/' + id;
    const dataId = [];
    dataId[id] = { ...props };
    console.log(typePerson);
    dispatch(setFirma(dataId));
    goTo(url);
  };
  const goTo = (url) => {
    navigate(url);
  };
  useEffect(() => {
    getImage();
  }, []);
  const getImage = async () => {
    try {
      const response = await MediaService.getDocumento(idExpediente, idPersona, name);
      setDocument(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const openFile = async () => {
    const url = document.base64;
    console.log(url);
    let w = window.open('', '_blank');
    if (document.mime.includes('image')) {
      let image = new Image();
      image.src = url;
      w.document.write(image.outerHTML);
      w.document.close();
    }
    if (document.mime.includes('pdf')) {
      w.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
      w.document.close();
    }
  };
  const changeState = (state) => {
    setStateModal(state);
  };
  const deleteFile = async () => {
    try {
      await MediaService.deleteDocumento(idExpediente, idPersona, name);
      changeState(false);
      getImage();
    } catch (e) {
      console.log(e);
      changeState(false);
      getImage();
    }
  };
  return (
    <>
      <Label text="Firma del solicitante" />
      <div className="flex">
        <ModalComponent
          show={stateModal}
          changeState={changeState}
          typeModal={'decision'}
          title={'Se eliminara el archivo'}
          stateModal={'warning'}
          actionOK={deleteFile}
        />
        <div>
          {Object.keys(document).length == 0 ? (
            <>
              <Button iconprefix={['fa', 'signature']} onClick={() => activeFirma()} />
            </>
          ) : (
            <>
              <Button iconprefix={['fa', 'eye']} label="Ver" onClick={() => openFile()} />
              {deleteItem ? (
                <Button
                  color="error"
                  iconprefix={['fa', 'trash']}
                  onClick={() => setStateModal(true)}
                />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

Firma.propTypes = {
  idPersona: PropTypes.string,
  idExpediente: PropTypes.string,
  typePerson: PropTypes.string,
  name: PropTypes.string,
  deleteItem: PropTypes.bool
};

export default Firma;
