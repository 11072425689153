import Card from 'Components/Card';
import ClientesService from 'Services/Http/Clientes.service';
import DomicilioService from 'Services/Http/Domicilio.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Image from '../Image';
import Geolocalizacion from '../Geolocalizacion';
import Label from 'Components/Label';
import Firma from '../Firma';
import Telefono from '../Telefono';

const Documentos = (props) => {
  const {
    title,
    idPersona,
    cliente = false,
    expediente,
    idDomicilio,
    typePerson,
    location = null,
    editable = false,
    phone
  } = props;
  const [persona, setPersona] = useState({});
  const [domicilio, setDomicilio] = useState({});
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      await getPerson();
      await getDomicilio();
    } catch (e) {
      console.log(e);
    }
  };
  const getPerson = async () => {
    try {
      const response = await ClientesService.getPersonById(idPersona);
      setPersona(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  const getDomicilio = async () => {
    try {
      const response = await DomicilioService.getDomicilioById(idDomicilio);
      setDomicilio(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Card title={title}>
        <div>
          <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col md:grid-cols-1 justify-stretch gap-3">
            <div>
              <div>Nombre completo: </div>
              <div className="font-semibold">{persona.nombreCompleto}</div>
            </div>
          </div>
          <Label text="Domicilio" />
          <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col md:grid-cols-2 justify-stretch gap-3">
            <div>
              <div>Calle: </div>
              <div className="font-semibold">{domicilio.calle}</div>
            </div>
            <div>
              <div>No exterior: </div>
              <div className="font-semibold">{domicilio.numeroExterior}</div>
            </div>
            <div>
              <div>No interior: </div>
              <div className="font-semibold">{domicilio.numeroInterior}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3">
            <div>
              <div>Codigo postal: </div>
              <div className="font-semibold">{domicilio.codigoPostal}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row my-3 md:grid md:grid-flow-col lg:grid-cols-3 justify-stretch gap-3">
            <div>
              <div>Colonia: </div>
              <div className="font-semibold">{domicilio.asentamiento}</div>
            </div>
            <div>
              <div>Municipio: </div>
              <div className="font-semibold">{domicilio.localidad?.D_mnpio}</div>
            </div>
            <div>
              <div>Estado: </div>
              <div className="font-semibold">{domicilio.localidad?.d_estado}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col lg:grid-cols-2 justify-stretch gap-3">
            <div>
              <div>Entre calle: </div>
              <div className="font-semibold">{domicilio.entreCalleA}</div>
            </div>
            <div>
              <div>y calle: </div>
              <div className="font-semibold">{domicilio.entreCalleB}</div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 my-3 md:grid md:grid-flow-col lg:grid-cols-2 justify-stretch gap-3">
            <div>
              <div>Observaciones: </div>
              <div className="font-semibold">{domicilio.observaciones}</div>
            </div>
          </div>
          {cliente ? (
            <>
              <Geolocalizacion
                idPersona={idPersona}
                idExpediente={expediente.id}
                location={location}
                deleteItem={editable}
              />
            </>
          ) : (
            ''
          )}
        </div>
        <Telefono type={cliente ? 'cliente' : 'aval'} phone={phone} />
        <Label text="Documentos" />
        <div className="ml-5">
          <Image
            label="Ine Frontal"
            icon={['fa', 'id-card']}
            idPersona={idPersona}
            idExpediente={expediente.id}
            typePerson={typePerson}
            name="INE_FRONTAL"
            deleteItem={editable}
          />
          <Image
            label="Ine Reverso"
            icon={['fa', 'money-check']}
            idPersona={idPersona}
            idExpediente={expediente.id}
            typePerson={typePerson}
            name="INE_REVERSO"
            deleteItem={editable}
          />
          <Image
            label="Comprobante de domicilio"
            icon={['fa', 'house-user']}
            idPersona={idPersona}
            idExpediente={expediente.id}
            typePerson={typePerson}
            name="DOMICILIO"
            deleteItem={editable}
          />
          {cliente ? (
            <>
              <Image
                label="Fachada de domicilio"
                icon={['fa', 'house-circle-check']}
                idPersona={idPersona}
                idExpediente={expediente.id}
                typePerson={typePerson}
                name="FACHADA"
                deleteItem={editable}
              />
            </>
          ) : (
            ''
          )}
          <Firma
            idPersona={idPersona}
            idExpediente={expediente.id}
            typePerson={typePerson}
            name="FIRMA"
            deleteItem={editable}
          />
        </div>
      </Card>
    </>
  );
};

Documentos.propTypes = {
  idPersona: PropTypes.number.isRequired,
  idDomicilio: PropTypes.number.isRequired,
  idExpediente: PropTypes.number,
  expediente: PropTypes.number.isRequired,
  title: PropTypes.string,
  full: PropTypes.bool,
  cliente: PropTypes.bool,
  typePerson: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  phone: PropTypes.object.isRequired,
  editable: PropTypes.bool
};

export default Documentos;
