import HttpClient from './HttpClient';

const OrigenService = {
  getTypeOrigin: () => {
    const path = 'origin/type';
    return HttpClient.get(path, {}, true);
  },
  getDataOrigin: (idPersona) => {
    const path = 'origin/id/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  saveOrigin: (params) => {
    const path = 'origin';
    return HttpClient.post(path, params, true);
  }
};

export default OrigenService;
