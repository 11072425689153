import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import Card from 'Components/Card';
import ControllerTextArea from 'Components/ControllerTextArea';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RechazoSchema } from 'Services/yup/form';

const MotivoModal = (props) => {
  const { changeState, params = {}, actionOK, title } = props;
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log(params);
    setMessage('');
  }, []);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      motivo: ''
    },
    resolver: yupResolver(RechazoSchema)
  });

  const submit = async (data) => {
    actionOK(data, setLoader);
  };

  return (
    <Card>
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        data-modal-hide="popup-modal"
        onClick={() => changeState(false)}>
        <FontAwesomeIcon icon={['fa', 'close']} size="2x" />
      </button>
      <div className="text-center bg-gray-100">
        <FontAwesomeIcon
          icon={['fa', 'triangle-exclamation']}
          size="3x"
          className="text-yellow-300"
        />
        <h3 className="text-lg font-normal m-2">{title}</h3>
        <div className="m-5">
          <ControllerTextArea
            control={control}
            name="motivo"
            placeholder="Motivo"
            error={errors?.motivo}
          />
        </div>
        {message ? <div className="m-5 text-red-400">{message}</div> : ''}
        <Button label="Rechazar" color="error" onClick={handleSubmit(submit)} loading={loader} />
        <Button label="Cancelar" onClick={() => changeState(false)} />
      </div>
    </Card>
  );
};

MotivoModal.propTypes = {
  changeState: PropTypes.func,
  actionOK: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.object,
  id: PropTypes.string
};

export default MotivoModal;
