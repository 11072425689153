import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'Components/Button';
import ControllerInput from 'Components/ControllerInput';
import Label from 'Components/Label';
import ModalComponent from 'Components/Modal';
import ConfiguracionService from 'Services/Http/Configuracion.service';
import { ConfiguracionCreditoSchema } from 'Services/yup/form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const Credito = () => {
  const [stateModal, setStateModal] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const [typeModal, setTypeModal] = useState('');
  const [loader, setLoader] = useState(false);
  const changeState = (state) => {
    setStateModal(state);
  };
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      taza: '',
      plazo: ''
    },
    resolver: yupResolver(ConfiguracionCreditoSchema)
  });

  const submit = async (data) => {
    try {
      await ConfiguracionService.saveConfigCredito(data);
      setLoader(false);
      setMessageModal('Guardado Exitoso');
      setTypeModal('success');
      setStateModal(true);
      reset();
    } catch (e) {
      console.log(e);
      setLoader(false);
      setStateModal(true);
      setTypeModal('error');
      setMessageModal('Ocurrio un error, revise e intente de nuevo');
    }
  };
  useEffect(() => {
    getConfigCredito();
  }, []);

  const getConfigCredito = async () => {
    try {
      const response = await ConfiguracionService.getConfigCredito();
      const data = response.data;
      if (Object.keys(data).length > 0) {
        const keys = Object.keys(getValues());
        await Promise.all(
          keys.map(async (item) => {
            if (data[item] != null) {
              await setValue(item, data[item]);
            }
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Label text={'Configuracion del credito'} />
      <form autoComplete="false">
        <div className="w-full lg:w-1/2 py-5 space-y-4 flex flex-col">
          <div className="w-full xl:w-1/2 space-y-4 flex flex-col">
            <div>
              <div>Taza: </div>
              <div>
                <ControllerInput
                  control={control}
                  name="taza"
                  error={errors?.taza}
                  placeholder="Taza"
                  onlyNumbers={true}
                />
              </div>
            </div>
            <div>
              <div>Pagos: </div>
              <div>
                <ControllerInput
                  control={control}
                  name="plazo"
                  error={errors?.plazo}
                  placeholder="Plazo"
                  onlyNumbers={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full space-y-2 flex flex-col lg:w-1/2 xl:w-1/4 lg:flex-row lg:space-y-0 lg:space-x-3">
          <Button
            onClick={handleSubmit(submit)}
            label="Guardar"
            color="primary"
            type="button"
            loading={loader}
          />
          <Button onClick={reset} label="Limpiar" type="button" />
        </div>
      </form>
      <ModalComponent
        show={stateModal}
        changeState={changeState}
        typeModal="notificacion"
        title={messageModal}
        stateModal={typeModal}
      />
    </>
  );
};

export default Credito;
