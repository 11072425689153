import { Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Numeralia from './Numeralia';

const DashboardRouter = (
  <Route path="" element={<Dashboard />}>
    <Route path="" element={<Numeralia />} />
  </Route>
);

export default DashboardRouter;
