import HttpClient from './HttpClient';

const ConfiguracionService = {
  getConfigCredito: () => {
    const path = 'config/document/credito';
    return HttpClient.get(path, {}, true);
  },
  saveConfigCredito: (params) => {
    const path = 'config/document/credito';
    return HttpClient.post(path, params, true);
  }
};

export default ConfiguracionService;
