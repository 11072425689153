import { Route } from 'react-router-dom';
import Configuracion from './Configuracion';
import Credito from './Credito';

const ConfiguracionRouter = (
  <Route path="config" element={<Configuracion />}>
    <Route path="credito" element={<Credito />} />
  </Route>
);

export default ConfiguracionRouter;
