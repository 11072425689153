import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'Components/Button';
import SmsService from 'Services/Http/Sms.service';
import PropTypes from 'prop-types';
import { useEffect, useId, useState } from 'react';
import VerificationInput from 'react-verification-input';
const VerificacionModal = (props) => {
  const { changeState, params } = props;
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const [labelSend, setLabelSend] = useState('Enviar');
  const [messageStatus, setMessageStatus] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSend, setMessageSend] = useState('');
  const [loader, setLoader] = useState(false);
  const [loaderSend, setLoaderSend] = useState(false);
  const dinamicId = useId('smsOtp');
  let interval = null;
  console.log(dinamicId);
  const codeLength = 6;
  useEffect(() => {
    setLabelSend('Enviar');
    setCode('');
    setMessageError('');
    setMessageStatus('');
    setSuccess(false);
    return () => {
      clearInterval(interval);
      setLabelSend('Enviar');
      setCode('');
      setMessageError('');
      setMessageStatus('');
      setSuccess(false);
      setLoaderSend(false);
    };
  }, []);

  useEffect(() => {
    setLabelSend('Enviar');
    setCode('');
    setMessageError('');
    setMessageStatus('');
    setSuccess(false);
  }, [params]);

  const validar = async () => {
    setLoader(true);
    setMessageError('');
    setMessageStatus('');
    try {
      const data = { code, movil: params.movil };
      const response = await SmsService.validarSms(data, params.type);
      setMessageStatus(response?.data?.message);
      setCode('');
      setSuccess(true);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
      setMessageError(e?.data?.message);
    }
  };

  const sendSms = async () => {
    setLoaderSend(true);
    setCode('');
    setMessageError('');
    setMessageStatus('');
    setSuccess(false);
    try {
      await SmsService.sendSms(params, params.type);
      setMessageSend('Mensaje enviado, espere por favor');
      interval = setTimeout(function () {
        setMessageSend('');
        setLoaderSend(false);
      }, 20000);
      setCode('');
      setLabelSend('Reenviar');
    } catch (e) {
      console.log(e);
      setLoaderSend(false);
      setLabelSend('Reenviar');
    }
  };

  const cerrar = async () => {
    clearInterval(interval);
    setSuccess(false);
    setCode('');
    changeState(false);
    setMessageSend('');
    setMessageError('');
    setMessageStatus('');
    setLoaderSend(false);
  };

  return (
    <div key={dinamicId}>
      <button
        type="button"
        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
        data-modal-hide="popup-modal"
        onClick={() => cerrar()}>
        <FontAwesomeIcon icon={['fa', 'close']} />
      </button>
      <div className="p-4 md:p-5 text-center">
        <h3 className="text-lg font-normal">Codigo de verificacion</h3>
        <h3 className="text-md font-normal">{params.movil}</h3>
        {messageStatus ? (
          <h1 className="text-xl font-normal text-green-500">{messageStatus}</h1>
        ) : (
          ''
        )}
        {messageError ? <h1 className="text-xl font-normal text-red-500">{messageError}</h1> : ''}

        <div className="my-5 flex grid justify-center content-center">
          <VerificationInput length={codeLength} value={code} onChange={setCode} />
        </div>
        {messageSend ? (
          <div className="my-5 flex grid justify-center content-center">
            <h1 className="text-xl font-normal text-blue-500">{messageSend}</h1>
          </div>
        ) : (
          ''
        )}
        {success ? (
          <Button label="Cerrar" onClick={() => cerrar()} color="primary" />
        ) : (
          <Button
            label="Validar"
            onClick={() => validar()}
            color="success"
            disabled={codeLength == code.length ? false : true}
            loading={loader}
          />
        )}
        <Button
          label={'Limpiar'}
          onClick={() => {
            setCode('');
            setMessageError('');
            setMessageStatus('');
          }}
        />
        <Button
          label={labelSend}
          onClick={() => {
            sendSms();
          }}
          loading={loaderSend}
        />
      </div>
    </div>
  );
};

VerificacionModal.propTypes = {
  params: PropTypes.object,
  changeState: PropTypes.func,
  actionOK: PropTypes.func,
  stateModal: PropTypes.string,
  title: PropTypes.string
};

export default VerificacionModal;
