import React from 'react';
import { Route } from 'react-router-dom';

const AuthComponent = React.lazy(() => import('./Auth'));
const LoginComponent = React.lazy(() => import('./Login'));
const AuthRouter = (
  <Route path="/" element={<AuthComponent />}>
    <Route path="" element={<LoginComponent />} />
  </Route>
);

export default AuthRouter;
