import React from 'react';
import { Route } from 'react-router-dom';
import ListarClienteComponente from './Listar-Cliente';
import DetalleCliente from './Detalle-Cliente';
import DetalleCredito from './Detalle-Credito';

const ClientesComponent = React.lazy(() => import('./Clientes'));
const ClientesRouter = (
  <Route path="cliente" element={<ClientesComponent />}>
    <Route path="" element={<ListarClienteComponente />} />
    <Route path="detalle/:id" element={<DetalleCliente />} />
    <Route path="credito/:id" element={<DetalleCredito />} />
  </Route>
);

export default ClientesRouter;
