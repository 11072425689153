import ProgressBar from 'Components/ProgressBar';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const Card = (props) => {
  const { children, title, className = '', loader = false } = props;
  const [loaderBar, setLoaderBar] = useState(loader);
  useEffect(() => {
    setLoaderBar(loader);
  }, [loader]);
  return (
    <>
      <div className={`border border-gray-200 rounded-lg shadow bg-gray-100 ${className}`}>
        {loaderBar ? <ProgressBar /> : ''}
        <div className="p-4 sm:p-6">
          <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl">{title}</h5>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  loader: PropTypes.bool
};

export default Card;
