import DashboardService from 'Services/Http/Dashboard.service';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
const CardNumbers = (props) => {
  const { title, number } = props;
  return (
    <div className="flex flex-col block w-full border rounded-lg shadow-secondary-1">
      <div className="px-6 py-3 bg-blue-500 text-white rounded-t-lg uppercase text-center">
        {title}
      </div>
      <div className="p-6 bg-gray-100">
        <div className="mb-2 text-3xl leading-tight text-gray-700 text-center font-bold">
          {number}
        </div>
      </div>
    </div>
  );
};
CardNumbers.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number
};

const Numeralia = () => {
  const [estadistica, setEstadistica] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      const response = await DashboardService.numeralia();
      const keys = Object.keys(response.data);
      if (keys.length > 0) {
        const data = keys.map((item, index) => (
          <CardNumbers key={'CardNumbers' + index} title={item} number={response.data[item]} />
        ));
        setEstadistica(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-2">{estadistica}</div>;
};

export default Numeralia;
