import Label from 'Components/Label';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Button from 'Components/Button';
import SmsService from 'Services/Http/Sms.service';

const ListarOtpsComponente = () => {
  const [otps, setOtps] = useState([]);
  useEffect(() => {
    getlastOtps();
  }, []);

  const getlastOtps = async () => {
    setOtps([]);
    const response = await SmsService.getSms();
    createRows(response.data);
  };

  const createRows = (rows) => {
    let data = rows.map((item, index) => (
      <tr
        key={'otps-row-' + index}
        className="odd:bg-white even:bg-blue-100 border-b hover:bg-blue-300 hover:cursor-pointer">
        <td className="px-6 py-4">{index + 1}</td>
        <td className="px-6 py-4">{item.movil}</td>
        <td className="px-6 py-4">{item.codigo}</td>
        <td className="px-6 py-4">{item.tipoTag}</td>
        <td className="px-6 py-4">{item.nombre + ' ' + item.paterno + ' ' + item.materno}</td>
        <td className="px-6 py-4">{item.verificadoTag}</td>
        <td className="px-6 py-4">{moment(item.created_at).format('DD/MM/YYYY hh:mm:ss a')}</td>
      </tr>
    ));
    setOtps(data);
  };
  return (
    <>
      <Label text={'Lista de codigos otp'} />
      <div className="flex flex-col grid grid-cols-1 md:grid md:grid-flow-col md:grid-cols-2 md:flex-row gap-3 my-3 justify-stretch">
        <div className="flex order-2 md:order-1">
          <Button iconprefix={['fa', 'rotate']} onClick={() => getlastOtps()} />
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-black">
          <thead className="text-xs text-white uppercase bg-blue-500">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Numero mov&iacute;l
              </th>
              <th scope="col" className="px-6 py-3">
                Codigo
              </th>
              <th scope="col" className="px-6 py-3">
                Tipo
              </th>
              <th scope="col" className="px-6 py-3">
                Solicitado por
              </th>
              <th scope="col" className="px-6 py-3">
                Estatus
              </th>
              <th scope="col" className="px-6 py-3">
                Fecha y hora de env&iacute;o
              </th>
            </tr>
          </thead>
          <tbody>{otps}</tbody>
        </table>
      </div>
    </>
  );
};

export default ListarOtpsComponente;
