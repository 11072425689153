import moment from 'moment';
import PropTypes from 'prop-types';
import Card from 'Components/Card';
import UsersService from 'Services/Http/Users.service';
import { useEffect, useState } from 'react';
import Button from 'Components/Button';

const Ejecutivo = (props) => {
  const { ejecutivo, idPersona } = props;
  const [ejecutivoData, setEjecutivoData] = useState(ejecutivo);
  const [usersAssigns, setUsersAssigns] = useState([]);
  const [userAssign, setUserAssign] = useState('');
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setEjecutivoData(ejecutivo);
  }, [ejecutivo]);

  const getUsers = async () => {
    const response = await UsersService.getUsersAssigns();
    const data = response.data;
    const options = data.map((item, index) => {
      if (item.user == ejecutivoData.asignado) {
        setUserAssign(item.id);
      }
      return (
        <option key={'users-opt-' + index} value={item.id} defaultValue>
          {item.nombreCompleto}
        </option>
      );
    });
    setUsersAssigns(options);
  };
  const changeUserAssign = (e) => {
    setUserAssign(e.target.value);
  };

  const saveChangeUserAssign = async () => {
    setLoader(true);
    try {
      const data = {
        idUsuario: userAssign,
        idPersona: idPersona
      };
      const response = await UsersService.saveUserAssign(data);
      setEjecutivoData({ ...ejecutivo, ...response.data.data });
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  return (
    <Card title="Ejecutivo" className="col-span-2 lg:col-span-1">
      <div className="grid grid-cols-1 md:grid-cols-2 my-3 gap-3">
        <div>
          Creado:
          <span className="font-semibold">{ejecutivoData.creador}</span>
        </div>
        <div>
          Fecha:
          <span className="font-semibold">
            {moment(ejecutivoData.created_at).format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 my-3 gap-3">
        <div>
          Asignado: <span className="font-semibold">{ejecutivoData.asignado}</span>
        </div>
        <div>
          Fecha:
          <span className="font-semibold">
            {moment(ejecutivoData.fechaAsignacion).format('DD/MM/YYYY')}
          </span>
        </div>
        {ejecutivo?.canAssign ? (
          <div className="grid grid-cols-1 col-span-2 gap-3">
            <div>
              <div>
                Re-asignar:
                <select value={userAssign} onChange={changeUserAssign} className="my-3 input-base">
                  {usersAssigns}
                </select>
              </div>
            </div>
            <div className="items-center cursor-pointer">
              <Button
                label="Asignar"
                color="primary"
                loading={loader}
                onClick={saveChangeUserAssign}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Card>
  );
};

Ejecutivo.propTypes = {
  idPersona: PropTypes.string.isRequired,
  ejecutivo: PropTypes.shape({
    creador: PropTypes.string,
    asignado: PropTypes.string,
    fechaAsignacion: PropTypes.string,
    created_at: PropTypes.string,
    canAssign: PropTypes.bool
  }).isRequired
};

export default Ejecutivo;
