import React from 'react';
import { Route } from 'react-router-dom';
import ModulesRouter from './Modules/Modules.router';
import UsersRouter from './Users/Users.router';
import ConfiguracionRouter from './Configuracion/Configuracion.router';
import OtpRouter from './Otp/Otp.router';

const AdminisrtracionComponent = React.lazy(() => import('./Administracion'));
const AdministracionRouter = (
  <Route path="admin" element={<AdminisrtracionComponent />}>
    {ModulesRouter}
    {UsersRouter}
    {ConfiguracionRouter}
    {OtpRouter}
  </Route>
);

export default AdministracionRouter;
