import Button from 'Components/Button';
import PropTypes from 'prop-types';
const BtnDescargas = (props) => {
  const { name, path } = props;
  const onButtonClick = () => {
    const pdfUrl = path + name;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Button
        iconprefix={['fa', 'file']}
        color="primary"
        onClick={() => onButtonClick(name)}
        label={name}
      />
    </>
  );
};

BtnDescargas.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

const Descargas = () => {
  return (
    <>
      <BtnDescargas name="CONVENIO_DE_PAGO_SIMPLE.docx" path="/formatos/" />
      <BtnDescargas name="Verificativo_de_expediente.docx" path="/formatos/" />
      <BtnDescargas name="Garantía_prendaria_sin_transmisión_de_posesión.docx" path="/formatos/" />
    </>
  );
};

export default Descargas;
