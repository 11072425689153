import HttpClient from './HttpClient';

const CreditoService = {
  saveCredit: (params) => {
    const path = 'credit/application';
    return HttpClient.post(path, params, true);
  },
  getDataCredit: (idPersona) => {
    const path = 'credit/application/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  creditNewApplication: (idPersona) => {
    const path = 'credit/application/' + idPersona;
    return HttpClient.put(path, {}, true);
  },
  solicitarPreAutorizacion: (idPersona) => {
    const path = 'credit/proceeding/' + idPersona;
    return HttpClient.get(path, {}, true);
  },
  rechazo: (params) => {
    const path = 'credit/proceeding/reject';
    return HttpClient.post(path, params, true);
  },
  rechazoExpediente: (params) => {
    const path = 'credit/proceeding/reject/expediente';
    return HttpClient.post(path, params, true);
  },
  expediente: (id) => {
    const path = 'credit/proceeding/validation/id/' + id;
    return HttpClient.get(path, {}, true);
  },
  validacion: (id) => {
    const path = 'credit/proceeding/validation/id/' + id;
    return HttpClient.post(path, {}, true);
  },
  validaciones: (params) => {
    const path = 'credit/proceeding/validation';
    return HttpClient.get(path, params, true);
  },
  geolocationSave: (params) => {
    const path = 'credit/geolocation';
    return HttpClient.post(path, params, true);
  },
  geolocationDelete: (params) => {
    const path = 'credit/geolocation';
    return HttpClient.delete(path, params, true);
  },
  autorizacion: (idPersona, idExpediente) => {
    const path = 'credit/proceeding/autorizacion/id/' + idPersona + '/expediente/' + idExpediente;
    return HttpClient.post(path, {}, true);
  },
  autorizaciones: (params) => {
    const path = 'credit/proceeding/autorizacion';
    return HttpClient.get(path, params, true);
  },
  creditoById: (id) => {
    const path = 'credit/proceeding/id/' + id;
    return HttpClient.get(path, {}, true);
  },
  autorizar: (params) => {
    const path = 'credit/proceeding/autorizar';
    return HttpClient.post(path, params, true);
  },
  creditos: (params) => {
    const path = 'credit/process';
    return HttpClient.get(path, params, true);
  },
  dispercion: (id) => {
    const path = 'credit/proceeding/dispercion/' + id;
    return HttpClient.post(path, {}, true);
  },
  liquidacion: (id) => {
    const path = 'credit/proceeding/liquidacion/' + id;
    return HttpClient.post(path, {}, true);
  },
  cambioEstatus: (id, params) => {
    const path = 'credit/proceeding/estatus/' + id;
    return HttpClient.post(path, params, true);
  },
  historico: (id) => {
    const path = 'credit/history/' + id;
    return HttpClient.get(path, {}, true);
  },
  solicitudes: (params) => {
    const path = 'credit/applications';
    return HttpClient.get(path, params, true);
  }
};

export default CreditoService;
