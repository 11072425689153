import * as yup from 'yup';

const yupNumber = (tipo) => {
  return yup
    .string()
    .max(10, 'el numero debe ser a 10 digitos')
    .min(10, 'el numero debe ser a 10 digitos')
    .required('El campo ' + tipo + ' es requerido');
};

export const CrearClienteSchema = yup.object({
  nombre: yup.string().required('El campo nombre es requerido'),
  paterno: yup.string().required('El campo apellido paterno es requerido'),
  materno: yup.string().required('El campo apellido materno es requerido'),
  fechaNacimiento: yup.string().required('La fecha de nacimiento es requerida'),
  idEstadoCivil: yup.string().required('Debe seleccionar un estado civil'),
  curp: yup.string()
});

export const LoginSchema = yup.object({
  user: yup.string().required('Este campo es requerido'),
  pass: yup.string().required('Este campo es requerido'),
  token: yup.string().required('Se requiere validar recaptcha')
});

export const CrearUsuarioSchema = yup.object({
  user: yup.string().required('El campo usuario es requerido'),
  email: yup
    .string()
    .matches(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      'El email no cuenta con el formato'
    )
    .required('El campo usuario es requerido'),
  nombre: yup.string().required('El campo nombre es requerido'),
  paterno: yup.string().required('El campo apellido paterno es requerido'),
  materno: yup.string().required('El campo apellido materno es requerido'),
  password: yup.string().required('El campo contraseña es requerido'),
  passwordConfirm: yup.string().required('El campo confirmar contraseña es requerido'),
  role: yup.string().required('El campo role es requerido')
});

export const CambioPasswordSchema = yup.object({
  password: yup
    .string()
    .min(6, 'La contraseña debe ser de minimo 6 caracteres')
    .required('El campo contraseña es requerido'),
  passwordConfirm: yup
    .string()
    .required('El campo confirmar contraseña es requerido')
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden')
});

export const DomicilioUsuarioSchema = yup.object({
  showManual: yup.boolean(),
  calle: yup.string().required('El campo calle es requerido'),
  numeroExterior: yup.string().required('El campo numero exterior es requerido'),
  numeroInterior: yup.string(),
  codigoPostal: yup
    .string()
    .length(5, 'El codigo postal debe ser de 5 digitos')
    .required('El campo codigo postal es requerido'),
  estado: yup.object().when('showManual', {
    is: true,
    then: () => yup.string().required('El campo estado es requerido'),
    otherwise: () => yup.string()
  }),
  municipio: yup.object().when('showManual', {
    is: true,
    then: () => yup.string().required('El campo municipio es requerido'),
    otherwise: () => yup.string()
  }),
  asentamiento: yup.object().when('showManual', {
    is: true,
    then: () => yup.string().required('El campo colonia es requerido'),
    otherwise: () => yup.string()
  }),
  entreCalleA: yup.string(),
  idTipoDomicilio: yup.string().required('El campo tipo de vivienda es requerido'),
  viviendoMeses: yup.string().required('El campo Meses viviendo en el domicilio es requerido'),
  viviendoAnios: yup.string().required('El campo Años viviendo en el domicilio es requerido'),
  entreCalleB: yup.string(),
  observaciones: yup.string()
});

export const TelefonoPersonaSchema = yup.object({
  idTipoTelefono: yup.string().required('El campo tipo telefono es requerido'),
  telefono: yup
    .string()
    .max(10, 'el numero debe ser a 10 digitos')
    .min(10, 'el numero debe ser a 10 digitos')
    .required('El campo telefono es requerido')
});

export const OrigenUsuarioSchema = yup.object({
  idTipoOrigen: yup.string().required('El campo referencia es requerido'),
  nombre: yup.object().when('idTipoOrigen', {
    is: (val) => {
      return val == 2 ? true : false;
    },
    then: () => yup.string().required('El campo nombre es requerido'),
    otherwise: () => yup.string()
  }),
  numero: yup.object().when('idTipoOrigen', {
    is: (val) => {
      return val == 2 ? true : false;
    },
    then: () => yup.string().required('El campo numero es requerido'),
    otherwise: () => yup.string()
  })
});

export const CreditoSchema = yup.object({
  montoSolicitado: yup.string().required('El campo monto solicitado es requerido'),
  capacidadDePago: yup.string().required('El campo capacidad de pago es requerido'),
  observaciones: yup.string()
});

export const EmpleoSchema = yup.object({
  idOcupacion: yup.string().required('Requerido'),
  ingresoMensual: yup.string().required('Requerido'),
  dependientes: yup.string().required('Requerido'),
  domicilioDeTrabajo: yup.string(),
  lugarDeTrabajo: yup.string(),
  telefono: yup.string().max(10, 'el numero debe ser a 10 digitos').nullable().optional()
});

export const RechazoSchema = yup.object({
  motivo: yup.string().required('El motivo es requerido')
});

export const ConyugeSchema = yup.object({
  idOcupacion: yup.string().required('La Ocupacion es requerido'),
  telefono: yupNumber('Telefono'),
  nombreCompleto: yup.string().required('El nombre es requerido')
});

export const AutorizarCreditoSchema = yup.object({
  dispercion: yup.string().required('La fecha de dispercion es requerida'),
  montoAprovado: yup.string().required('El monto aprobado es requerido'),
  montoDispercion: yup.string()
});

export const ConfiguracionCreditoSchema = yup.object({
  taza: yup.number().required('El campo Taza es requerido'),
  plazo: yup.number().required('El campo Plazo es requerido')
});

export const ReferenciaSchema = yup.object({
  idParentesco: yup.string().required('Requerido'),
  nombreCompleto: yup.string().required('Requerido'),
  celular: yupNumber('Celular'),
  fijo: yup.object().when('celular', {
    is: (val) => {
      return val.length == 0 ? true : false;
    },
    then: () => yupNumber('Fijo'),
    otherwise: () => yup.string()
  }),
  aniosDeConocer: yup.string().required('Requerido')
});

export const AvalSchema = yup.object({
  idOcupacion: yup.string().required('Requerido'),
  idRelacion: yup.string().required('Requerido'),
  nombre: yup.string().required('Requerido'),
  paterno: yup.string().required('Requerido'),
  materno: yup.string().required('Requerido'),
  movil: yupNumber('Movil'),
  fijo: yup.object().when('movil', {
    is: (val) => {
      return val.length == 0 ? true : false;
    },
    then: () => yupNumber('Fijo'),
    otherwise: () => yup.string().max(10, 'el numero debe ser a 10 digitos')
  })
});

export const FindSchema = yup.object({
  text: yup.string().required('Requerido')
});
