const ProgressBar = () => {
  return (
    <>
      <div className="progress primary">
        <div className="progress-bar primary"></div>
      </div>
    </>
  );
};

export default ProgressBar;
