import { Route } from 'react-router-dom';
import Otp from './Otp';
import ListarOtpsComponente from './Listar-otps';

const OtpRouter = (
  <Route path="otp" element={<Otp />}>
    <Route path="" element={<ListarOtpsComponente />} />
  </Route>
);

export default OtpRouter;
