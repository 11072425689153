import { Route } from 'react-router-dom';
import Creditos from './Creditos';
import ListarCreditosComponente from './Listar-Creditos';

const CreditosRouter = (
  <Route path="credito" element={<Creditos />}>
    <Route path="" element={<ListarCreditosComponente />} />
  </Route>
);

export default CreditosRouter;
