import { Route } from 'react-router-dom';
import Autorizar from './Autorizar';
import ListarAutorizacionesComponente from './Listar-Autorizacion';

const AutorizarRouter = (
  <Route path="autorizar" element={<Autorizar />}>
    <Route path="" element={<ListarAutorizacionesComponente />} />
  </Route>
);

export default AutorizarRouter;
