import Card from 'Components/Card';
import EmpleoService from 'Services/Http/Empleo.service';
import { formatCurrency } from 'Utils/Functions';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Empleo = (props) => {
  const { idExpediente } = props;
  const [dataEmpleo, setDataEmpleo] = useState();
  useEffect(() => {
    getEmployee();
  }, []);
  const getEmployee = async () => {
    try {
      const response = await EmpleoService.getEmployeeById(idExpediente);
      setDataEmpleo(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Card title={'Empleo del cliente'} className="col-span-2 lg:col-span-1">
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Ocupacion</div>
            <div className="font-semibold">{dataEmpleo?.ocupacion}</div>
          </div>
          <div>
            <div>Ingreso mensual</div>
            <div className="font-semibold">{formatCurrency(dataEmpleo?.ingresoMensual)}</div>
          </div>
          <div>
            <div>Dependiente economicos</div>
            <div className="font-semibold">{dataEmpleo?.dependientes}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Negocio o empresa </div>
            <div className="font-semibold">{dataEmpleo?.lugarDeTrabajo}</div>
          </div>
          <div>
            <div>Telefono</div>
            <div className="font-semibold">{dataEmpleo?.telefono}</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between my-3">
          <div>
            <div>Direccion del empleo</div>
            <div className="font-semibold">{dataEmpleo?.domicilioDeTrabajo}</div>
          </div>
        </div>
      </Card>
    </>
  );
};
Empleo.propTypes = {
  idExpediente: PropTypes.string
};

export default Empleo;
